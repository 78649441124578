import {ActionTree, GetterTree, MutationTree} from "vuex";
import {UserState} from "~/util/store";

interface State extends UserState {}

const state = (): State => ({});

type RootState = ReturnType<any>;
type CustomState = ReturnType<typeof state>;

export const getters: GetterTree<CustomState, RootState> = {
    uid: (state) => state.uid,
};

export const mutations: MutationTree<CustomState> = {
    uid: (state, uid: string) => (state.uid = uid),
};

export const actions: ActionTree<CustomState, RootState> = {
    async initialize({ state, commit }, uid: string) {
        if (uid !== state.uid) {
            commit("session", []);
        }
    },
};
