import {Plugin} from "@nuxt/types";

type Api = {};

declare module "vue/types/vue" {
  // this.$api inside Vue components
  interface Vue {
    $api: Api;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$api inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $api: Api;
  }
  // nuxtContext.$api
  interface Context {
    $api: Api;
  }
}

declare module "vuex/types/index" {
  // this.$api inside Vuex stores
  interface Store<S> {
    $api: Api;
  }
}

const ApiPlugin: Plugin = (context, inject) => {
  context.$axios.onRequest(async (config) => {
    const token = await context.$fire.auth.currentUser?.getIdToken(true);
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = `application/json`;
    config.headers["Access-Control-Allow-Origin"] = `*`;
    return config;
  });

  // context.$axios.setBaseURL(process.env.apiBaseUrl!!);

  const api: Api = {};

  inject("api", api);
};

export default ApiPlugin;
