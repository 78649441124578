import {Plugin} from "@nuxt/types";
import Vue from "vue";
import {showError} from "~/util/general";

const ErrorHandlerPlugin: Plugin = (context) => {
    Vue.config.errorHandler = async (err: Error, vm: Vue, info: string) => {
        await showError(context, err, vm, info);
    };
}

export default ErrorHandlerPlugin;
