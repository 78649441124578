import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {CatalogApi} from "~/service/catalog/CatalogApi";
import {AreaType, Catalog, CognitiveImpairmentType, Gender, VisualAbilityType} from "~/model";

export class CatalogService extends GenericService<CatalogApi> {

    constructor(context: Context | Store<any>) {
        super(context, new CatalogApi(context));
    }

    async getGenderTypes(): Promise<Catalog<Gender>[]> {
        return ["male", "female"].map(code => ({
            code,
            text: this.getMessage(`catalog.gender.${code}`)
        } as Catalog<Gender>));
    }

    async getCognitiveImpairmentTypes(): Promise<Catalog<CognitiveImpairmentType>[]> {
        return ["dcg", "alz", "dcm", "dcl", "none"].map(code => ({
            code,
            text: this.getMessage(`catalog.cognitive.${code}`)
        } as Catalog<CognitiveImpairmentType>));
    }

    async getVisualAbilityTypes(): Promise<Catalog<VisualAbilityType>[]> {
        return ["reduced", "regular", "limited", "normal", "unknown"].map(code => ({
            code,
            text: this.getMessage(`catalog.visual.${code}`)
        } as Catalog<VisualAbilityType>));
    }

    async getAreaType(): Promise<Catalog<AreaType>[]> {
        return ["moods", "cognitive"].map(code => ({
            code,
            text: this.getMessage(`catalog.testType.${code}`)
        } as Catalog<AreaType>));
    }
}
