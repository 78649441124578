import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {PersonGroup as PersonGroupData, PersonGroupApi} from "~/service/person-group/PersonGroupApi";
import {PersonGroup} from "~/service/person-group/PersonGroup";
import {GenericService} from "~/service/GenericService";
import {Paginator} from "~/service/Generic";
import {toPersonGroup, toPersonGroupApi} from "~/service/person-group/PersonGroupMapper";

export class PersonGroupService extends GenericService<PersonGroupApi> {

    constructor(context: Context | Store<any>) {
        super(context, new PersonGroupApi(context));
    }

    async getByUser(userId: number, text: string, size:number = 10, page: number = 1):Promise<Paginator<PersonGroup>> {
        const result = await this.processApiCallWithException(() => this.api.getByUser(userId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toPersonGroup(d));
    }

    async getById(id: number):Promise<PersonGroup | null> {
        const result = await this.processApiCallWithException(() => this.api.getById(id));
        return result ? await toPersonGroup(result) : null;
    }

    async save(group:PersonGroup): Promise<PersonGroup | null> {
        let data: PersonGroupData | undefined = toPersonGroupApi(group);
        data && (data.reference = group.ref || "");
        data = await this.processApiCallWithException(() => this.api.save(data!!));
        return data ? toPersonGroup(data) : null;
    }

    async delete(group:PersonGroup): Promise<void> {
        const data = toPersonGroupApi(group);
        await this.api.delete(data);
    }
}
