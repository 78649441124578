import {PersonGroup} from "~/service/person-group/PersonGroup";
import {PersonGroup as PersonGroupData} from "~/service/person-group/PersonGroupApi";
import {PersonGroupEntity} from "~/service/person-group/PersonGroupRepository";
import {Reference} from "~/service/GenericRepository";
import {toSector} from "~/service/sector/SectorMapper";
import {toUser} from "~/service/user/UserMapper";
import {toContent, toContentApi} from "~/service/content/ContentMapper";
import {toPerson, toPersonApi} from "~/service/person/PersonMapper";
import {toSession, toSessionApi} from "~/service/session/SessionMapper";

export function toPersonGroup(data: PersonGroupData): PersonGroup {
    return {
        id: data.id,
        name: data.name,
        userId: data.userId,
        sectorId: data.sectorId,
        disabled: 0,
        showInManager: true,
        persons: (data.persons || []).map(toPerson),
        sessions: (data.sessions || []).map(toSession),
        contents: (data.contents || []).map(toContent),
        user: data.user && toUser(data.user),
        sector: data.sector && toSector(data.sector),
    }
}

export function toPersonGroupApi(data: PersonGroup): PersonGroupData {
    return {
        id: data.id,
        name: data.name,
        userId: data.userId,
        sectorId: data.sectorId,
        disabled: data.disabled,
        persons: (data.persons || []).map(toPersonApi),
        sessions: (data.sessions || []).map(toSessionApi),
        contents: (data.contents || []).map(toContentApi),
        reference: "",
    }
}

export function toPersonGroupEntity(data: PersonGroup, ref?: string, id?: number): PersonGroupEntity {
    return {
        ref: ref,
        id: id || data.id,
        name: data.name,
        owner: "",
        showInManager: !!data.showInManager,
        sessions: toPersonGroupSessionsEntity(data),
    }
}

export function toPersonGroupSessionsEntity(data: PersonGroup) {
    return (data.contents|| []).map(d => ({
        title: data.name,
        active: true,
        played: false,
        // session: new Reference(d)
    }));
}
