import {ContentGroup as UserGroupApiData} from "~/service/content-group/ContentGroupApi";
import {ContentGroup} from "~/service/content-group/ContentGroup";
import {toContent, toContentApi} from "~/service/content/ContentMapper";
import {toSector, toSectorApi} from "~/service/sector/SectorMapper";

export function toContentGroup(input: UserGroupApiData): ContentGroup {
    const {id, name, contents, sectorId, sector} = input;
    return {
        id,
        name,
        sectorId,
        sector: sector && toSector(sector),
        contents: (contents || []).map(toContent)
    }
}

export function toContentGroupApi(input: ContentGroup): UserGroupApiData {
    const {id, name, contents, sectorId, sector} = input;
    return {
        id,
        name,
        sectorId,
        sector: sector && toSectorApi(sector),
        contents: (contents || []).map(toContentApi)
    }
}
