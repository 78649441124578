import {GenericApi, VivirtualApiResponse} from "~/service/GenericApi";
import {AreaType} from "~/service/catalog/CatalogApi";

export type Area = {
  id: number;
  name: string;
  type: AreaType;
  disabled?:number;
};

export class AreaApi extends GenericApi {

  async getAll(): Promise<VivirtualApiResponse<Area[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/areas`),
        200
    );
  }

  async getById(id: number): Promise<VivirtualApiResponse<Area | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/areas/${id}`),
        200
    );
  }

}
