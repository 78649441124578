import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {Sector} from "~/service/sector/SectorApi";
import {Content} from "~/service/content/ContentApi";
import {PersonGroup} from "~/service/person-group/PersonGroupApi";
import {Test} from "~/service/test/TestApi";
import {Person} from "~/service/person/PersonApi";

export interface SessionPerson {
  sessionId: number;
  personId: number;
  date: string | null;
  comments: string;
  person?: Person;
}

export type Session = {
  id?: number;
  sectorId: number;
  contentId: number;
  personGroupId: number;
  testId: number;
  name: string;
  date: string | null;
  persons: number;
  comments: string;
  test?: Test;
  sector?: Sector;
  content?: Content;
  person_group?: PersonGroup;
  participants: SessionPerson[];
}

export class SessionApi extends GenericApi {

  async getByUser(userId:number, text:string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Session>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/sessions?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByUserAndDate(userId:number, from: string, to:string): Promise<VivirtualApiResponse<Session[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/sessions?from=${from}&to=${to}`),
        200
    );
  }

  async getByGroupAndDate(groupId:number, from: string, to:string): Promise<VivirtualApiResponse<Session[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/sessions?from=${from}&to=${to}`),
        200
    );
  }

  async getByGroup(groupId:number, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Session>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/sessions?page=${page}&size=${size}`),
        200
    );
  }

  async getByGroupAndId(groupId:number, id: number): Promise<VivirtualApiResponse<Session | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/sessions/${id}`),
        200
    );
  }

  async save(input: Session): Promise<VivirtualApiResponse<Session | null>> {
    return this.errorHandler(
        async () => await this.$axios.put(`/api/v1/person-groups/${input.personGroupId}/sessions`, input),
        201
    );
  }

  async delete(id: number): Promise<VivirtualApiResponse<void>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`/api/v1/sessions/${id}`),
        200
    );
  }

}
