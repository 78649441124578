




















































































































































/* eslint-disable no-new */
import {SlideYDownTransition, ZoomCenterTransition} from "vue2-transitions";
import {Component, Vue} from "nuxt-property-decorator";
import {mapActions, mapGetters} from "vuex";
import {menu} from "~/model/menu";
import {showAlert, showProcessing} from "~/util/general";

@Component({
  components: {
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  computed: mapGetters({
    name: "auth/name",
    email: "auth/email",
    picture: "auth/picture",
    isAdmin: "auth/isAdmin",
    isClient: "auth/isClient",
    isLoggedIn: "auth/isLoggedIn",
    alertType: "type",
    alertMessage: "message",
    processing: "processing"
  }),
  methods: mapActions({
    resetError: "resetError",
    logout: "auth/logout",
    signIn: "auth/signIn",
  }),
})
export default class extends Vue {
  /* eslint-disable no-new */
  [x: string]: any;

  private icons = [
    {
      icon: "mdi-facebook",
      url: "https://www.facebook.com/VivirtualECV",
    },
    {
      icon: "mdi-twitter",
      url: "https://twitter.com/vivirtual_",
    },
    {
      icon: "mdi-linkedin",
      url: "https://es.linkedin.com/company/vivirtual",
    },
  ];

  private drawer = false;

  private color = "light-blue darken-4";

  private processing!: boolean;

  private name!: string;

  private email!: string;

  private picture!: string;

  private isAdmin!: boolean;

  private isClient!: boolean;

  private isLoggedIn!: boolean;

  private alertMessage!: string;

  private alertType!: string;

  private logout!: () => Promise<void>;

  private signIn!: () => Promise<void>;

  private resetError!: () => Promise<void>;

  private get role() {
    if (this.isAdmin) return 'admin';
    if (this.isClient) return 'client';
    return 'unregistered';
  }

  private get menu() {
    return menu(this)[this.role];
  }

  private get isDrawerEnabled() {
    return this.isLoggedIn && this.menu.length > 1;
  }

  private get alertColor() {
    if (this.alertType === 'success') {
      return 'success';
    } else if (this.alertType === 'info') {
      return 'info';
    } else if (this.alertType === 'warning') {
      return 'warning';
    } else {
      return 'error';
    }
  }

  private get alertMessageClass() {
    const data: any = {};
    data[`white--text`] = true;
    return data;
  }

  private get showAlert(): boolean {
    return !!this.alertMessage;
  }

  private set showAlert(data: boolean) {
    this.resetError();
  }

  private get isLargeVersion() {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  private get isSmallVersion() {
    return !this.isLargeVersion;
  }

  private get showDrawer() {
    return this.isLargeVersion || this.drawer;
  }

  private set showDrawer(value: boolean) {
    this.drawer = value;
  }

  async errorCaptured (err: Error, vm: Vue, info: string) {
    console.error(err, info);
    await showProcessing(this, false);
    await showAlert(this, {
      type: "error",
      message: "error.generic",
    });
    return false;
  };
}
