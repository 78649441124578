import {Entity, GenericRepository} from "~/service/GenericRepository";
import {ResourceType, ResourceViewType} from "~/service/resource/Resource";

export const COLLECTION = "resources";

export interface ResourceEntity extends Entity {
    name: string;
    description?:string;
    type: ResourceType;
    viewType: ResourceViewType;
    filename?: string;
    fileId?: string;
    lowRes?: boolean;
    copyright?: string;
    sector: number;
    guideId?: string;
    thumbnail?: string;
    exercises?: string;
}

export class ResourceContentRepository extends GenericRepository {

    public async getAll():Promise<ResourceEntity[]> {
        return await this.getByFilters();
    }

    public async getById(id:string):Promise<ResourceEntity> {
        return await this.getDocumentByKey(id) as ResourceEntity;
    }

    protected getCollectionName(): string {
        return COLLECTION;
    }

}
