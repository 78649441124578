import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {PersonApi} from "~/service/person/PersonApi";
import {Person} from "~/service/person/Person";
import {GenericService} from "~/service/GenericService";
import {Paginator} from "~/service/Generic";
import {toPerson, toPersonApi} from "~/service/person/PersonMapper";

export class PersonService extends GenericService<PersonApi> {

    constructor(context: Context | Store<any>) {
        super(context, new PersonApi(context));
    }

    async getById(personId: number):Promise<Person | null> {
        const result = await this.processApiCallWithException(async () => this.api.getById(personId));
        return result ? toPerson(result) : null;
    }

    async getByUser(userId: number, text: string, size:number, page: number):Promise<Paginator<Person>> {
        const result = await this.processApiCallWithException(() => this.api.getByUser(userId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toPerson(d));
    }

    async getByGroup(groupId: number, text: string, size: number, page: number):Promise<Paginator<Person>> {
        const result = await this.processApiCallWithException(async () => this.api.getByGroup(groupId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toPerson(d));
    }

    async getByGroupAndId(groupId: number, id: number):Promise<Person | null> {
        const result = await this.processApiCallWithException(async () => await this.api.getByGroupAndId(groupId, id));
        return result ? toPerson(result) : null;
    }

    async getAllByGroup(groupId: number):Promise<Person[]> {
        const result = await this.processApiCallWithException(async () => this.api.getAllByGroup(groupId));
        return (result || []).map(toPerson);
    }

    async save(item:Person): Promise<Person | null> {
        const data = toPersonApi(item);
        const result = await this.processApiCallWithException(async () => await this.api.save(data));
        return result ? toPerson(result) : null;
    }

    async delete(item:Person): Promise<void> {
        await this.processApiCallWithException(async () => await this.api.delete(item.groupId, item.id!!));
    }
}
