import {ActionTree, GetterTree, MutationTree} from "vuex";
import {errorHandler} from "~/util/store";
import {YTVideoMetadata} from "~/model";

type State = {
  ytCache: Map<string, YTVideoMetadata>;
};

type RootState = ReturnType<typeof state>;

export const state = (): State => ({
  ytCache: new Map<string, YTVideoMetadata>(),
});

export const getters: GetterTree<RootState, RootState> = {
  ytCache: (state) => state.ytCache,
};

export const mutations: MutationTree<RootState> = {
  setYtResource: ( state, { id, value }: { id: string; value: YTVideoMetadata }) => {
    state.ytCache.set(id, value);
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getYoutubeMetadata({ getters, commit, dispatch }, id: string): Promise<YTVideoMetadata | null> {
    try {
      const ytCache = getters.ytCache
        ? (getters.ytCache as Map<string, YTVideoMetadata>)
        : new Map<string, YTVideoMetadata>();
      let response: YTVideoMetadata | null = ytCache.get(id) || await this.$services.miscellaneous.getYoutubeMetadata(id);
      response && await commit("setYtResource", { id, value: response });
      return response;
    } catch (e) {
      await errorHandler(dispatch, e);
    }
    return null;
  },
};
