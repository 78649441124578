import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {Content} from "~/service/content/ContentApi";
import {Sector} from "~/service/sector/SectorApi";

export type ContentGroup = {
    id?:number;
    name: string;
    sectorId: number;
    sector?: Sector;
    contents?: Content[];
}

export class ContentGroupApi extends GenericApi {

    async getById(id: number): Promise<VivirtualApiResponse<ContentGroup>> {
        return this.errorHandler(
            async () => await this.$axios.get(`/api/v1/content-groups/${id}`),
            200
        );
    }

    async getAll(size: number, page: number): Promise<VivirtualApiResponse<Paginator<ContentGroup>>> {
        return this.errorHandler(
            async () => await this.$axios.get(`/api/v1/content-groups?page=${page}&size=${size}`),
            200
        );
    }

    async getByText(text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<ContentGroup>>> {
        return this.errorHandler(
            async () => await this.$axios.get(`/api/v1/content-groups?text=${text}&page=${page}&size=${size}`),
            200
        );
    }

    async getBySector(sectorId: number, text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<ContentGroup>>> {
        return this.errorHandler(
            async () => await this.$axios.get(`/api/v1/sectors/${sectorId}/content-groups?text=${text}&page=${page}&size=${size}`),
            200
        );
    }

    async save(input: ContentGroup): Promise<VivirtualApiResponse<ContentGroup>> {
        return this.errorHandler(
            async () => await this.$axios.put(`/api/v1/content-groups`, input),
            201
        );
    }

    async delete(id: number): Promise<VivirtualApiResponse<ContentGroup>> {
        return this.errorHandler(
            async () => await this.$axios.delete(`/api/v1/content-groups/${id}`),
            200
        );
    }
}
