import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericApi, Paginator as PaginatorApi, VivirtualApiResponse} from "~/service/GenericApi";
import {Entity, Paginator as PaginatorEntity} from "~/service/GenericRepository";
import {Paginator} from "~/service/Generic";

export abstract class GenericService<A extends GenericApi> {
    protected readonly api!:A;
    protected readonly context!: Context | Store<any>;

    protected constructor(context: Context | Store<any>, api: A) {
        this.context = context;
        this.api = api;
    }

    protected getMessage(key: string): string | undefined {
        const context: any = this.context;
        const { store } = context;
        return store?.$i18n?.t(key)?.toString();
    }

    protected static entityRefToId<T extends Entity>(entity: T | null | undefined): string | undefined {
        return entity?.ref ? entity.ref?.split("/").slice(-1).pop() : undefined;
    }

    protected async processApiCall<T>(callable: () => Promise<VivirtualApiResponse<T>>, errorThrowing: boolean = false):Promise<T | null> {
        const response = await callable();
        const { status, data } = response;
        if (status === "error" && errorThrowing) {
            throw response.error!!;
        } else if (status === "error") {
            return null;
        }
        return data || null;
    }

    protected async processApiCallWithException<T>(callable: () => Promise<VivirtualApiResponse<T>>):Promise<T | undefined> {
        const response = await callable();
        const { status, data } = response;
        if (status === "error") {
            throw response.error!!;
        }
        return data;
    }

    protected async processApiCallWithoutException<T>(callable: () => Promise<VivirtualApiResponse<T>>):Promise<T | null> {
        const response = await callable();
        const { status, data } = response;
        if (status === "error") {
            return null;
        }
        return data || null;
    }

    protected async paginatorApiToPaginator<T, R>(input: PaginatorApi<T>, mapper: (data: T) => Promise<R | null>): Promise<Paginator<R>> {
        const result: Paginator<R> = {
            data: [],
            current: input.current_page,
            last: input.last_page,
            size: input.per_page,
            total: input.total
        };

        for (let item of input.data) {
            const data = await mapper(item);
            data !== null && result.data.push(data);
        }

        return result;
    }

    protected async paginatorEntityToPaginator<T, R>(input: PaginatorEntity<T>, mapper: (data: T) => Promise<R | null>): Promise<Paginator<R>> {
        const result: Paginator<R> = {
            data: [],
            current: input.current,
            last: input.last,
            size: input.size,
            total: input.total
        };

        for (let item of input.data) {
            const data = await mapper(item);
            data !== null && result.data.push(data);
        }

        return result;
    }


}
