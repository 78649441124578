import {Sector as SectorData} from "~/service/sector/SectorApi";
import {Sector} from "~/service/sector/Sector";

export function toSector(input:SectorData): Sector {
    return {
        id: input.id,
        name: input.name,
        disabled:input.disabled || 0
    }
}

export function toSectorApi(input:Sector): SectorData {
    return {
        id: input.id!!,
        name: input.name,
        disabled:input.disabled || 0
    }
}
