import {Plugin} from "@nuxt/types";
import {Context, Inject} from "@nuxt/types/app";
import {UserService} from "~/service/user/UserService";
import {SectorService} from "~/service/sector/SectorService";
import {PersonGroupService} from "~/service/person-group/PersonGroupService";
import {ContentService} from "~/service/content/ContentService";
import {ContentGroupService} from "~/service/content-group/ContentGroupService";
import {PersonService} from "~/service/person/PersonService";
import {SessionService} from "~/service/session/SessionService";
import {TestService} from "~/service/test/TestService";
import {CatalogService} from "~/service/catalog/CatalogService";
import {AreaService} from "~/service/area/AreaService";
import {ResultService} from "~/service/results/ResultService";
import {MiscellaneousService} from "~/service/miscellaneous/MiscellaneousService";

type Services = {
    user: UserService,
    sector: SectorService,
    content: ContentService,
    contentGroup: ContentGroupService,
    person: PersonService,
    personGroup: PersonGroupService,
    sessions: SessionService,
    test: TestService,
    area: AreaService,
    catalog: CatalogService,
    result: ResultService,
    miscellaneous: MiscellaneousService,
};

declare module "vue/types/vue" {
    // this.$api inside Vue components
    interface Vue {
        $services: Services;
    }
}

declare module "@nuxt/types" {
    // nuxtContext.app.$api inside asyncData, fetch, plugins, middleware, nuxtServerInit
    interface NuxtAppOptions {
        $services: Services;
    }
    // nuxtContext.$api
    interface Context {
        $services: Services;
    }
}

declare module "vuex/types/index" {
    // this.$api inside Vuex stores
    interface Store<S> {
        $services: Services;
    }
}

const ServicesPlugin: Plugin = (context:Context, inject: Inject) => {

    const services: Services = {
        user: new UserService(context),
        sector: new SectorService(context),
        content: new ContentService(context),
        contentGroup: new ContentGroupService(context),
        person: new PersonService(context),
        personGroup: new PersonGroupService(context),
        sessions: new SessionService(context),
        test : new TestService(context),
        area: new AreaService(context),
        catalog: new CatalogService(context),
        result: new ResultService(context),
        miscellaneous: new MiscellaneousService(context),
    };

    inject("services", services);
};

export default ServicesPlugin;
