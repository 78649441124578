import {Plugin} from "@nuxt/types";
import pages from "~/config/pages";

const ICONS: any = {
  ['control']: {
    default: 'mdi-account',
  },
  ['home']: {
    default: 'mdi-view-dashboard',
  },
  ['dashboard']: {
    default: 'mdi-view-dashboard-outline',
  },
  ['user']: {
    default: 'mdi-account',
    add: 'mdi-account-plus',
  },
  ['content']: {
    default: 'mdi-movie',
    add: 'mdi-movie-plus',
    search: 'mdi-movie-search',
    video: 'mdi-filmstrip',
    panorama: 'mdi-image',
    picture: 'mdi-image',
    playlist: 'mdi-image-multiple',
    ['playlist-add']: 'mdi-image-plus',
    download: 'mdi-file-download',
    upload: 'mdi-file-upload-outline',
  },
  ['content-group']: {
    default: 'mdi-image-multiple',
    add: 'mdi-file-image-plus',
    search: 'mdi-image-search',
  },
  ['person']: {
    default: 'mdi-account-tie',
    add: 'mdi-account-plus',
    search: 'mdi-account-search',
  },
  ['person-group']: {
    default: 'mdi-account-group',
    add: 'mdi-account-multiple-plus',
  },
  ['session']: {
    default: 'mdi-google-cardboard',
    add: 'mdi-note-plus-outline',
    calendar: 'mdi-calendar-outline',
  },
  ['session-person']: {
    default: 'mdi-account-tie',
    search: 'mdi-account-search',
  },
  ['test']: {
    default: 'mdi-order-bool-descending',
    add: 'mdi-text-box-plus',
  },
  ['test-question']: {
    default: 'mdi-text-box-plus-outline',
    add: 'mdi-text-box-outline',
  }
}

export class Provider {
  icon (model: string, action: string = 'default') {
    return ICONS[model]?.[action] || "";
  }

  page(model: string, action: string | null = null, id: string | number = 'new') {
    let page = (pages as any)[model];
    page = action ? page[action] : page;
    return page ? page('en', id) : null;
  }
}

declare module "vue/types/vue" {
  // this.$api inside Vue components
  interface Vue {
    $provider: Provider;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$api inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $provider: Provider;
  }
  // nuxtContext.$api
  interface Context {
    $provider: Provider;
  }
}

declare module "vuex/types/index" {
  // this.$api inside Vuex stores
  interface Store<S> {
    $provider: Provider;
  }
}

const IconProviderPlugin: Plugin = (context, inject) => {
  inject("provider", new Provider());
};

export default IconProviderPlugin;
