import {ActionTree, GetterTree, MutationTree} from "vuex";
import {Paginator} from "~/service/Generic";
import {AlertType} from "~/model";

export const EMPTY_PAGINATOR: Paginator<any> = {
  current: 0, data: [], last: 0, size: 0, total: 0
}

export type State = {
  processing: boolean,
  message: string,
  type: AlertType,
}

export interface AlertData {
  message: string,
  type: AlertType,
  parameters?: any[];
}

export const state = (): State => ({
  processing: false,
  message: "",
  type: "error",
});

export type RootState = ReturnType<typeof state>;

const getMessage = (context: any, value: AlertData, byDefault: string = '') =>
    context?.$i18n?.t(value.message, value.parameters) || (value.message.includes(" ") ? value.message : byDefault)

export const getters: GetterTree<RootState, RootState> = {
  processing: (state) => state.processing,
  message: (state) => state.message,
  type: (state) => state.type,
};

export const mutations: MutationTree<RootState> = {
  processing: (state, value: boolean) => state.processing = value,
  alertType: (state, type: AlertType) => (state.type = type),
  alert: (state, value: State) => {
    state.message = value.message;
    state.type = value.type
  },
  reset: (state) => {
    state.message = "";
    state.type = "error"
  },
};

export const actions: ActionTree<RootState, RootState> = {
  cleanupAction({ commit }) {
    commit("reset");
    commit("auth/RESET_AUTH_USER");
  },

  setProcessing({ commit, dispatch }, value: boolean) {
    commit("processing", value, { root: true });
  },

  setError({ commit, dispatch }, value) {
    dispatch('showAlert', {
      message: value,
      type: "error",
    });
  },

  showAlert({ commit }, value: AlertData) {
    const message = getMessage(this, value, 'There was an unknown error please try again and if the error continue please contact with info@vivirutal.es.');
    commit("alert", { message, type: value.type }, { root: true });
    setTimeout(() => {
      commit("reset");
    }, 5000);
  },

  resetError({ commit }) {
    commit("reset");
  },
};
