import {Person as PersonApiData} from "~/service/person/PersonApi";
import {Person} from "~/service/person/Person";
import {dateToString, stringToDate} from "~/util/utils";
import {
    toApiCognitiveType,
    toApiGenderType, toApiVisualType,
    toCognitiveType,
    toGenderType,
    toVisualType
} from "~/service/catalog/CatalogMapper";
import {toPersonGroup} from "~/service/person-group/PersonGroupMapper";

export function toPerson(data: PersonApiData):Person {
    const { id, groupId, name, surname, dob, gender, cognitive_impairment_type, visual_ability, comments, disabled } = data;
    return {
        id,
        groupId: groupId,
        name,
        surname,
        comments,
        disabled,
        dob: stringToDate(dob),
        gender: toGenderType(gender),
        cognitive: toCognitiveType(cognitive_impairment_type),
        visual: toVisualType(visual_ability),
        group: data.group && toPersonGroup(data.group),
        ref: `${id}`,
    }
}

export function toPersonApi(data: Person):PersonApiData {
    const { id, groupId, name, surname, dob, gender, cognitive, visual, comments, disabled } = data;
    return {
        id,
        groupId: groupId,
        name,
        surname,
        comments,
        disabled,
        dob: dateToString(dob),
        gender: toApiGenderType(gender),
        cognitive_impairment_type: toApiCognitiveType(cognitive),
        visual_ability: toApiVisualType(visual)
    }
}
