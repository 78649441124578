import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {ContentApi} from "~/service/content/ContentApi";
import {Content} from "~/service/content/Content";
import {GenericService} from "~/service/GenericService";
import {ContentEntity, ContentRepository} from "~/service/content/ContentRepository";
import {Paginator} from "~/service/Generic";
import {toContent, toContentApi} from "~/service/content/ContentMapper";
import {Resource} from "~/service/resource/Resource";

export class ContentService extends GenericService<ContentApi> {

    private repository!: ContentRepository;

    constructor(context: Context | Store<any>) {
        super(context, new ContentApi(context));
        this.repository = new ContentRepository(context);
    }

    async getAll(text: string, size: number, page: number):Promise<Paginator<Content>> {
        const result = await this.processApiCallWithException(async () => this.api.getAll(text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toContent(d));
    }

    async getPrivateAllByUser(userId: number, text: string, size: number, page: number):Promise<Paginator<Content>> {
        const result = await this.processApiCallWithException(async () => this.api.getPrivateAllByUser(userId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toContent(d));
    }

    async getByText(text: string, size: number, page: number):Promise<Paginator<Content>> {
        const result = await this.processApiCallWithException(async () => this.api.getByText(text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toContent(d));
    }

    async getBySector(sectorId: number, text: string, size: number, page: number):Promise<Paginator<Content>> {
        const result = await this.processApiCallWithException(async () => this.api.getBySector(sectorId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toContent(d));
    }

    // return all the contents for the user and the groups that the user belong
    async getByUser(userId: number, text: string, size: number, page: number):Promise<Paginator<Content>> {
        const result = await this.processApiCallWithException(async () => await this.api.getByUser(userId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toContent(d));
    }

    async getAllByUser(userId: number):Promise<Content[]> {
        const result = await this.processApiCall(async () => await this.api.getAllByUser(userId), false);
        return (result || []).map(toContent);
    }

    async getById(id: number):Promise<Content | null> {
        let data = await this.processApiCallWithException(async () => await this.api.getById(id));
        return data ? toContent(data) : null;
    }

    async getByUserAndId(userId: number, id: number):Promise<Content | null> {
        let data = await this.processApiCallWithException(async () => await this.api.getByUserAndId(userId, id));
        return data ? toContent(data) : null;
    }

    async save(content:Content): Promise<Content | null> {
        let data= toContentApi(content);
        const result = await this.processApiCallWithException(async () => await this.api.save(data));
        return result ? toContent(result) : null;
    }

    async getAllLegacy(): Promise<Content[]> {
        const data = ( await this.repository.getAll() || []);
        return data.map(ContentService.toContent);
    }

    async delete(content: Content) {
        content.id && await this.processApiCallWithException(async () => await this.api.delete(content.id!!));
    }

    private static toContent(entity: ContentEntity):Content {
        const data = {
            private: false,
            external: true,
            title: entity.title,
            description: entity.description || "",
            sectorId: 0,
            disabled: entity.disabled,
            exercises: entity.exercises,
            guide: entity.guideId,
            thumbnail: entity.thumbnail,
            resource: entity.resource || {
                code: "",
                type: "video",
                viewType: "360"
            } as Resource,
        };

        (data.resource?.items || []).forEach(d => {
            if (d.type === "panorama" && !d.code.startsWith("panorama/")) {
                d.code = `panorama/${d.code}`;
            }
        })

        delete data.resource.id;

        return data;
    }
}
