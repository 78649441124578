import cloneDeep from "clone-deep";
import {Entity} from "~/service/GenericRepository";

export const equals = (d1: any, d2: any): boolean =>
  JSON.stringify(d1) === JSON.stringify(d2);

export const clone = <T>(value: T): T => cloneDeep(value);
//export const clone = <T>(value: T): T => JSON.parse(JSON.stringify(cloneDeep(value)));
// export const clone = <T>(value: T): T => Object.assign({}, cloneDeep(value));

export function entityRefToId<T extends Entity>(entity: T | null | undefined): string | undefined {
  return entity?.ref ? entity.ref?.split("/").slice(-1).pop() : undefined;
}

export function numberToString(input: number, digits: number = 1) {
  let value = input.toString();
  return value.padStart((digits - value.length) + 1, '0');
}

export function today() {
  const date = new Date();
  date.setUTCHours(0,0,0,0);
  return date;
}

export function isFutureDate(date: Date | null = null) {
  return !date || today() < date;
}

export function stringToDate(data?: string | null): Date | null {
  let result = null;
  const dates: number[] = (data || "").split(/[-\s:]/).map(d => parseInt(d)).filter(d => !isNaN(d));
  if (dates.length === 3) {
    result = new Date(dates[0], dates[1] - 1, dates[2], 23, 59, 59);
  } else if (dates.length === 6) {
    result = new Date(dates[0], dates[1] - 1, dates[2], dates[3], dates[4], dates[5]);
  }
  return result;
}

// 2001-12-01 23:59:59
export function dateToString(data?: Date | null, time: boolean = false): string | null {
  if (!data) return null;
  const toString = (d: number) => d.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
  const value = [data.getFullYear(), data.getMonth() + 1, data.getDate()].map(toString).join("-");
  return time ? `${value} 23:59:50` : value;
}

export function stringToEncodedNumber(input: string) {
  return parseInt(Array.from(input).map(d => parseInt(d, 36)).join(''));
}

export function removeUndefinedField<T>(value:T) {
  if (Array.isArray(value)) {
    (value as any[]).forEach(removeUndefinedField);
  } else if (typeof value === 'object') {
    Object.keys(value).forEach(k => {
      const key = k as keyof T;
      const data = value[key];
      data ? removeUndefinedField(data) : (delete value[key]);
    })
  }
}

export function isEmpty<T> (data: T | null) {
  return Array.isArray(data) ? (data as T[]).length === 0 : data === null;
}

export function containsArray<T, K>(array: T[], key: K, getKey: (a:T) => K = (a) => (a as any)): boolean {
  return !!array.find(d => getKey(d) === key);
}

export function getArray<T, K>(array: T[], key: K, getKey: (a:T) => K, defaultElement: T): T {
  let item = array.find(d => getKey(d) === key);
  if (!item) {
    item = defaultElement;
    array.push(item);
  }
  return item;
}

export function addArray<T>(array: T[], element: T, getKey: (a:T) => any = (a) => a): boolean {
  const hasToBeAdded = !array.map(getKey).find(d => d === getKey(element));
  hasToBeAdded && array.push(element);
  return hasToBeAdded;
}

export function removeArray<T> (array: T[], element: T, getKey: (a:T) => any = (a) => a): boolean {
  const index = array.map(getKey).indexOf(getKey(element));
  if (index > -1) {
    array.splice(index, 1);
    return true;
  }
  return false;
}

export function replaceArray<T> (array: T[], element: T, newElement: T, getKey: (a:T) => any = (a) => a): boolean {
  const index = array.findIndex(d => getKey(d) === getKey(newElement));
  if (index > -1) {
    array[index] = newElement;
    return true;
  }
  return false;
}

export function replaceOrAddArray<T> (array: T[], element: T, newElement: T, getKey: (a:T) => any = (a) => a): boolean {
  if (!replaceArray(array, element, newElement, getKey)) {
    array.push(newElement);
  }
  return true;
}

export function removeDuplicatesArray<T, K> (array: T[], getKey: (a:T) => K | T = (a) => a): T[] {
  const keys: (T | K)[] = [];
  const result: T[] = [];
  array.forEach(d => {
    const key = getKey(d);
    if (!keys.includes(key)) {
      result.push(d);
      keys.push(key);
    }
  })
  return result;
}

export function switchElementsArray<T, K> (array: T[], pos1: number, pos2: number) {
  if (pos1 >= 0 && pos1 < array.length && pos2 >= 0 && pos2 < array.length) {
    const result = [...array];
    const item1 = array[pos1];
    const item2 = array[pos2];
    result[pos2] = item1;
    result[pos1] = item2;
    return result;
  }
  return array;
}
