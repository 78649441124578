import {ActionTree, GetterTree, MutationTree} from "vuex";
import {Person} from "~/model";
import {UserState} from "~/util/store";

interface State extends UserState {
    persons: Person[];
}

const state = (): State => ({
    persons: [] as Person[],
});

type RootState = ReturnType<any>;
type CustomState = ReturnType<typeof state>;

export const getters: GetterTree<CustomState, RootState> = {
    uid: (state) => state.uid,
    persons: (state) => state.persons,
};

export const mutations: MutationTree<CustomState> = {
    uid: (state, uid: string) => (state.uid = uid),
    persons: (state, persons: Person[]) => (state.persons = persons),
};

export const actions: ActionTree<CustomState, RootState> = {
    async initialize({ state, commit }, uid: string) {
        if (uid !== state.uid) {
            commit("persons", []);
        }
    },
};
