import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {Paginator} from "~/service/Generic";
import {SessionApi} from "~/service/session/SessionApi";
import {Session} from "~/service/session/Session";
import {toSession, toSessionApi} from "~/service/session/SessionMapper";

export class SessionService extends GenericService<SessionApi> {

    constructor(context: Context | Store<any>) {
        super(context, new SessionApi(context));
    }

    async getByUser(userId: number, text: string, size:number, page: number):Promise<Paginator<Session>> {
        const response = await this.api.getByUser(userId, text, size, page);
        const {status, data} = response;
        if (status === "error") {
            throw response.error!!;
        }
        return await this.paginatorApiToPaginator(data!!, async (d) => toSession(d));
    }

    async getByUserAndDate(userId:number, from: string, to:string): Promise<Session[]> {
        const result = await this.processApiCallWithoutException(async () => this.api.getByUserAndDate(userId, from, to));
        return result?.map(toSession) || [];
    }

    async getByPersonGroupAndDate(groupId:number, from: string, to:string): Promise<Session[]> {
        const result = await this.processApiCallWithoutException(async () => this.api.getByGroupAndDate(groupId, from, to));
        return result?.map(toSession) || [];
    }

    async getByGroupAndId(groupId: number, id: number):Promise<Session | null> {
        const response = await this.api.getByGroupAndId(groupId, id);
        const {data, status} = response;
        if (status === "error") {
            throw response.error!!;
        }
        return data ? toSession(data) : null;
    }

    async save(item:Session):Promise<Session | null> {
        const data =  toSessionApi(item);
        const result = await this.processApiCallWithException(async () => this.api.save(data));
        return result ? toSession(result) : null;
    }

    async delete(session: Session) {
        session.id && await this.processApiCallWithException(async () => this.api.delete(session.id!!));
    }
}
