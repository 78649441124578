import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {encrypt} from "~/util/encode";
import {Resource} from "~/service/resource/Resource";
import {Sector} from "~/service/sector/SectorApi";
import {User} from "~/service/user/UserApi";

export type Content = {
  id?: number;
  ownerId?: number;
  sectorId: number;
  title: string;
  description: string;
  code?: string;
  exercises?: string;
  thumbnail?: string;
  guide?: string;
  data?: Resource;
  disabled?:number;
  sector?: Sector;
  external?: boolean;
  owner?:User;
}

export class ContentApi extends GenericApi {

  // contents
  async getByReference(reference: string): Promise<VivirtualApiResponse<Content | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/contents/reference/${encrypt(reference)}`),
        200
    );
  }

  async getById(id: number): Promise<VivirtualApiResponse<Content | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/contents/${id}`),
        200
    );
  }

  // contents
  async getAll(text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Content>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/contents?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getPrivateAllByUser(userId: number, text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Content>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/contents/private?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByText(text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Content>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/contents?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  // contents
  async getBySector(sectorId: number, text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Content>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/sectors/${sectorId}/contents?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  // contents
  async getAllByUser(userId: number): Promise<VivirtualApiResponse<Content[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/contents`),
        200
    );
  }

  async getByUser(userId: number, text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Content>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/contents?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByUserAndId(userId: number, id: number): Promise<VivirtualApiResponse<Content | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/contents/${id}`),
        200
    );
  }

  // others
  async save(content: Content):Promise<VivirtualApiResponse<Content | null>> {
    return this.errorHandler(
      async () => await this.$axios.put(`/api/v1/contents`, content, {}),
      201
    );
  }

  async delete(id: number): Promise<VivirtualApiResponse<Content>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`/api/v1/contents/${id}`),
        200
    );
  }

}
