import {Context} from "@nuxt/types";
import {User as FirebaseAuthUser} from "@firebase/auth-types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {User as UserApiData, UserApi} from "~/service/user/UserApi";
import {AuthUser, User, UserInfo} from "~/service/user/User";
import {Paginator} from "~/service/Generic";
import {toUser, toUserApi, toUserInfo} from "~/service/user/UserMapper";

export class UserService extends GenericService<UserApi> {

    constructor(context: Context | Store<any>) {
        super(context, new UserApi(context));
    }

    private getFirebaseAuthUser():FirebaseAuthUser | null {
        return this.context?.$fire?.auth?.currentUser;
    }

    async getAuthenticatedUser(authUser: FirebaseAuthUser | null = null):Promise<AuthUser | null> {
        const { email } = authUser || this.getFirebaseAuthUser() || {};
        const response = !email ? { status: 'error', data: undefined, error: "Unknown error" } : await this.api.getUserByEmail(email!!);
        const { data } = response;
        if (response.status === "error") {
            return null;
        }
        const calculatedAuthUser = authUser || this.getFirebaseAuthUser()!;
        const user = await this.toUser(data!, calculatedAuthUser) as AuthUser;
        user.photoURL = calculatedAuthUser.photoURL || undefined;
        return user;
    }

    async getByText(text: string, size: number, page: number):Promise<Paginator<User>> {
        const result = await this.processApiCallWithException(async () => this.api.getByText(text, size, page));
        return await this.paginatorApiToPaginator(result!!,  async (d) => toUser(d));
    }

    async getAll(size: number, page: number):Promise<Paginator<User>> {
        const result = await this.processApiCallWithException(async () => this.api.getAll(size, page));
        return await this.paginatorApiToPaginator(result!!,  async (d) => toUser(d));
    }

    async getById(id: number):Promise<User | null> {
        const data = await this.processApiCallWithException(() => this.api.getById(id));
        return toUser(data!!);
    }

    async save(user:User): Promise<User> {
        const data = await this.processApiCallWithException(() => this.api.save(toUserApi(user)));
        return toUser(data!!);
    }

    async delete(user: User): Promise<void> {
        await this.processApiCallWithException(() => this.api.delete(user.id!!));
    }

    async getInfo(id: number): Promise<UserInfo | null> {
        const data = await this.processApiCallWithoutException(() => this.api.getInfo(id));
        return toUserInfo(data!!);
    }

    private async toUser(apiData: UserApiData, authUser: FirebaseAuthUser | undefined = undefined): Promise<User | null> {
        const email = authUser?.email || apiData?.email || "";
        const entity = null//!authUser && email ? await this.repository.getByEmail(email) : null;
        const data = apiData || entity;
        if (data === null) {
            return null;
        }
        // const { name, roles } = { ...data };
        // const id = apiData?.id;
        // const ref = entity?.ref;
        // const uid = data?.uid || entity?.uid;
        // const phone = data?.phone || entity?.phone;
        // const sectorId = data?.sector || entity?.sector;
        // const groups:string[] = (entity?.groups || []).map(d => d.ref);
        // const contents:string[] = (entity?.sessions || []).map(d => d.ref);
        // const sector = sectorId && await this.context.$services.sector.getById(sectorId);
        const user: User = (apiData && toUser(apiData)) || {};

        if (authUser) {
            const { uid, displayName } = authUser;
            user.uid = uid;
            user.name = displayName || user.name;
        }
        return user;
    }
}
