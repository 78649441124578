import {ActionTree, GetterTree, MutationTree} from "vuex";
import {AuthUser} from '~/service/user/User';
import {UserService} from '~/service/user/UserService';
import {createUser} from "~/model";

const ADMIN_ROLES = ['admin'];
const CLIENT_ROLES = [...ADMIN_ROLES, 'client', 'cliente'];

const isAdminByRoles = (roles: string[]): boolean =>
    roles.filter(d => ADMIN_ROLES.includes(d)).length > 0

const isClientByRoles = (roles: string[]): boolean =>
    roles.filter(d => CLIENT_ROLES.includes(d)).length > 0

export type State = {
  user: AuthUser | null;
}

export const state = ():State => ({
  user: null,
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  user: (state) => state.user,
  id: (state) => state.user?.id,
  uid: (state) => state.user?.uid,
  email: (state) => state.user?.email,
  name: (state) => state.user?.name,
  picture: (state) => state.user?.photoURL,
  isLoggedIn: (state) => state?.user?.uid,
  isClient: (state) => isClientByRoles(state.user?.roles || []),
  isAdmin: (state) => isAdminByRoles(state.user?.roles || []),
};

export const mutations: MutationTree<RootState> = {
  RESET_USER: (state) => {
    state.user = null;
  },
  CHANGE_USER_ROLES: (state, roles: string[]) => {
    if (state.user) {
      state.user.roles = roles;
      state.user.isAdmin = isAdminByRoles(roles);
      state.user.isClient = isClientByRoles(roles);
    }
  },
  CHANGE_USER: (state, user?: AuthUser | null) => {
    if (user) {
      state.user = user
    }
  },
  LOAD_USER: (state, data) => {
    const { authUser } = data || {};
    state.user = {
      ...createUser(),
      uid: authUser?.uid,
      name: authUser?.displayName || "",
      email: authUser?.email || "",
      photoURL: authUser?.photoURL || "",
    } as AuthUser;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  onLogout({ commit }) {
    commit("RESET_USER");
  },

  // login
  async onAuthStateChanged({ commit, dispatch }, { authUser/*, claims */}) {
    // firebase. User
    if (!authUser) {
      await dispatch("onLogout");
    } else {

      // configuring the axios for the token
      this.$axios.onRequest(async (config) => {
        const token = await authUser?.getIdToken();
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Content-Type"] = `application/json`;
        config.headers["Access-Control-Allow-Origin"] = `*`;
        return config;
      });

      const service = new UserService(this);
      const user = await service.getAuthenticatedUser(authUser);
      // if (user === null) {
      //   await dispatch("setError", 'You cannot login, Please contact with the administrator of the page', { root: true });
      // }

      // changing the roles
      await commit("CHANGE_USER", user);
      await commit("CHANGE_USER_ROLES", user?.roles || []);
      // await dispatch("client/user/initialize", user, { root: true });
    }
  },

  async signIn() {
    // this.$fire.auth.getRedirectResult().then(() => this.$router.push("/"));
    const provider = new this.$fireModule.auth.GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    await this.$fire.auth.signInWithRedirect(provider);
  },

  async logout({ dispatch }) {
    try {
      await this.$fire.auth.signOut();
      this.$router.push(this.$provider.page('login'));
    } catch (e) {
      await dispatch("onLogout");
    }
  },
};
