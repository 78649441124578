import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {Sector} from "~/service/sector/SectorApi";
import {User} from "~/service/user/UserApi";
import {Content} from "~/service/content/ContentApi";
import {Person} from "~/service/person/PersonApi";
import {Session} from "~/service/session/SessionApi";


export type PersonGroup = {
  id?:number;
  userId:number;
  sectorId:number;
  name:string;
  reference: string;
  disabled?:number;
  contents?: Content[];
  sessions?: Session[];
  persons?: Person[];
  sector?:Sector;
  user?:User;
}

export class PersonGroupApi extends GenericApi {

  // contents
  async getByUser(userId:number, text:string, size?: number, page?: number): Promise<VivirtualApiResponse<Paginator<PersonGroup>>> {
    const params = Object.entries({text, size, page})
        .filter(([key, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/person-groups?${params}`),
        200
    );
  }

  async getById(id:number): Promise<VivirtualApiResponse<PersonGroup | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${id}`),
        200
    );
  }

  async save(input: PersonGroup): Promise<VivirtualApiResponse<PersonGroup>> {
    return this.errorHandler(
        async () => await this.$axios.put(`/api/v1/users/${input.userId}/person-groups`, input),
        201
    );
  }

  async delete(input: PersonGroup): Promise<VivirtualApiResponse<void>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`/api/v1/person-groups/${input.id}`),
        200
    );
  }

}
