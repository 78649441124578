import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {clone} from "~/util/utils";
import type {DocumentReference, DocumentSnapshot, FirebaseFirestore, Query} from "@firebase/firestore-types";

export class Reference {
    public readonly ref:string;

    constructor(ref: string) {
        this.ref = ref;
    }
}

export interface Entity {
    ref?:string;
    collection?:string;
}

export interface Paginator<T> {
    data: T[],
    current: number;
    last: number;
    size: number
    total: number
}

export abstract class GenericRepository {
    protected readonly db: FirebaseFirestore;
    private readonly context!: Context | Store<any>;

    constructor(context: Context | Store<any>) {
        this.context = context;
        this.db = context.$fire.firestore;
    }

    protected getMessage(key: string): string | undefined {
        const context: any = this.context;
        const { store } = context;
        return store?.$i18n?.t(key)?.toString();
    }

    protected abstract getCollectionName(): string;

    private toEntityValue(value:any):any {
        let result;
        if (!value) {
            return value;
        } else if (Array.isArray(value)) {
            result = (value as any[]).map(d => this.toEntityValue(d));
        } else if ( value['path'] && typeof value === 'object') {
            const { path } = (value as DocumentReference);
            result = new Reference(path);
        } else if (typeof value === "object") {
            const data:any = value;
            Object.keys(value).forEach((key) => {
                data[key] = this.toEntityValue(data[key]);
                !data[key] && delete data[key];
            });
            result = data;
        } else {
            result = value;
        }
        return result;
    }

    protected toEntity<T extends Entity>(doc: DocumentSnapshot):T {
        const data = doc.data()!;
        const path = doc.ref.path;
        const result:Partial<T> = {ref: path, collection: path.split("/")[0] } as Partial<T>;
        Object.keys(data).forEach(key => {
            result[(key as keyof T)] = this.toEntityValue(data[key]);
        })
        return result as T;
    }

    protected refreshEntity<T extends Entity>(entity: T):T {
        const result:Partial<T> = clone(entity);
        Object.keys(entity).forEach(key => {
            result[(key as keyof T)] = this.toEntityValue(entity[(key as keyof T)]);
        })
        return result as T;
    }

    private toDocumentDataValue<T>(value:T):any {
        let result;
        if (Array.isArray(value)) {
            result = (value as any[]).map(d => this.toDocumentDataValue(d)).filter(d => d != undefined);
        } else if ( value instanceof Reference) {
            const { ref } = (value as Reference);
            result = ref ? this.db.doc(ref) : undefined;
        } else if (typeof value === "object") {
            const data:any = value;
            Object.keys(value).forEach((key) => {
                data[key] = this.toDocumentDataValue(data[key]);
                !data[key] && delete data[key];
            });
            result = data;
        } else {
            result = value;
        }
        return result;
    }

    protected toDocumentData<T extends Entity>(entity: T):any {
        const result:any = {};
        const data:T = entity;
        Object.keys(entity).filter(key => !['ref', 'collection'].includes(key)).forEach(key => {
            result[key] = this.toDocumentDataValue(data[(key as keyof T)]);
            !result[key] && delete result[key];
        })
        return result;
    }

    protected async getDocumentByKey<T extends Entity> (key: string, collection: string = this.getCollectionName()): Promise<T | null> {
        const reference:DocumentReference<T> = this.db.collection(collection).doc(key) as DocumentReference<T>;
        const snapshot = await reference.get();
        return snapshot.exists ? this.toEntity(snapshot) as T : null;
    };

    protected async getDocumentByReference<T extends Entity> (reference: string): Promise<T | null> {
        const ref:DocumentReference<T> = this.db.doc(reference) as DocumentReference<T>;
        const data = await ref.get();
        return data.exists ? this.toEntity(data) as T : null;
    };

    protected async getByFilters<T extends Entity>(filters: Object = {}, collection: string = this.getCollectionName()):Promise<T[]> {
        let query:Query = this.db.collection(collection);
        for (const [key, value] of Object.entries(filters)) {
            query = query.where(key, "==", value);
        }
        return (await query.get()).docs.filter(d=>d.exists).map(d => this.toEntity(d));
    }
}
