import {
    CognitiveImpairmentType as ApiCognitiveImpairmentType,
    Gender as ApiGender,
    AreaType as ApiAreaType, VisualAbilityType as ApiVisualAbilityType
} from "~/service/catalog/CatalogApi";
import {CognitiveImpairmentType, Gender, AreaType, VisualAbilityType} from "~/service/catalog/Catalog";

export function toAreaType(data: ApiAreaType): AreaType {
    switch (data) {
        case "estados_animo": return "moods";
        case "areas_cognitivas": return "cognitive";
        default: return "cognitive";
    }
}

export function toApiAreaType(data: AreaType): ApiAreaType {
    switch (data) {
        case "moods": return "estados_animo";
        case "cognitive": return "areas_cognitivas";
    }
}

export function toGenderType(data: ApiGender): Gender {
    return data === "Hombre" ? "male" : "female";
}

export function toCognitiveType(data: ApiCognitiveImpairmentType): CognitiveImpairmentType {
    if (data === "DCG") return "dcg";
    if (data === "DCM") return "dcm";
    if (data === "DCL") return "dcl";
    if (data === "ALZ") return "alz";
    return "none";
}


export function toVisualType(data: ApiVisualAbilityType): VisualAbilityType {
    if (data === "Limitada") return "limited";
    if (data === "Reducida") return "reduced";
    if (data === "Regular") return "regular";
    if (data === "Adecuada") return "regular";
    return "unknown";
}

export function toApiGenderType(data: Gender): ApiGender {
    return data === "male" ? "Hombre" : "Mujer";
}

export function toApiCognitiveType(data: CognitiveImpairmentType): ApiCognitiveImpairmentType {
    if (data === "dcg") return "DCG";
    if (data === "dcm") return "DCM";
    if (data === "dcl") return "DCL";
    if (data === "alz") return "ALZ";
    return "None";
}

export function toApiVisualType(data: VisualAbilityType): ApiVisualAbilityType {
    if (data === "limited") return "Limitada";
    if (data === "reduced") return "Reducida";
    if (data === "regular") return "Regular";
    if (data === "normal") return "Adecuada";
    return "Desconocida";
}
