










/* eslint-disable no-new */
import {Component, Prop, Vue} from "nuxt-property-decorator";

interface Error {
  statusCode: number;
  message: string;
}

@Component({})
export default class extends Vue {

  @Prop({ default: [] })
  private readonly error!: Error;

  get layout() {
    return "empty";
  }
}
