import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {SectorApi} from "~/service/sector/SectorApi";
import {Sector} from "~/service/sector/Sector";
import {GenericService} from "~/service/GenericService";
import {toSector} from "~/service/sector/SectorMapper";

export class SectorService extends GenericService<SectorApi> {

    constructor(context: Context | Store<any>) {
        super(context, new SectorApi(context));
    }

    async getAll():Promise<Sector[]> {
        const response = await this.api.getAll();
        const {status, data, error} = response;
        if (status === "error") {
            throw error!!;
        }
        return data?.map(toSector) || [];
    }

    async getById(id: number):Promise<Sector | null> {
        const result = await this.processApiCallWithException(async () => this.api.getById(id));
        return result ? toSector(result) : null;
    }
}
