import {Session as SessionApiData, SessionPerson as SessionPersonApiData} from "~/service/session/SessionApi";
import {Session, SessionPerson} from "~/service/session/Session";
import {dateToString, stringToDate} from "~/util/utils";
import {toSector} from "~/service/sector/SectorMapper";
import {toContent} from "~/service/content/ContentMapper";
import {toPersonGroup} from "~/service/person-group/PersonGroupMapper";
import {toTest} from "~/service/test/TestMapper";
import {toPerson, toPersonApi} from "~/service/person/PersonMapper";

export function toSession(data: SessionApiData):Session {
    const { id, personGroupId, contentId, sectorId, testId, name, date, persons, comments, test, sector, content, person_group, participants} = data;
    return {
        id,
        sectorId,
        contentId,
        personGroupId: personGroupId,
        testId,
        name,
        persons,
        comments,
        date: stringToDate(date),
        test: test && toTest(test),
        sector: sector && toSector(sector),
        content: content && toContent(content),
        personGroup: person_group && toPersonGroup(person_group),
        participants: (participants || []).map(toSessionPerson),
    }
}

export function toSessionPerson(input: SessionPersonApiData): SessionPerson {
    const { person } = input;
    return {
        ...input,
        person: person && toPerson(person),
    }
}

export function toSessionApi(data: Session):SessionApiData {
    const { id, personGroupId, contentId, sectorId, testId, name, date, persons, comments, participants} = data;
    return {
        id,
        personGroupId: personGroupId,
        contentId,
        sectorId,
        testId,
        name,
        persons,
        comments,
        date: dateToString(date, true),
        participants: (participants || []).map(toSessionPersonApi),
    }
}

export function toSessionPersonApi(input: SessionPerson): SessionPersonApiData {
    const { person } = input;
    return {
        ...input,
        person: person && toPersonApi(person),
    }
}
