import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {ContentGroupApi} from "~/service/content-group/ContentGroupApi";
import {GenericService} from "~/service/GenericService";
import {ContentGroup} from "~/service/content-group/ContentGroup";
import {toContentGroup, toContentGroupApi} from "~/service/content-group/ContentGroupMapper";
import {Paginator} from "~/service/Generic";

export class ContentGroupService extends GenericService<ContentGroupApi> {

    constructor(context: Context | Store<any>) {
        super(context, new ContentGroupApi(context));
    }

    async getByText(text: string, size: number, page: number):Promise<Paginator<ContentGroup>> {
        const result = await this.processApiCallWithException(async () => this.api.getByText(text, size, page));
        return await this.paginatorApiToPaginator(result!!,  async (d) => toContentGroup(d));
    }

    async getAll(size: number, page: number):Promise<Paginator<ContentGroup>> {
        const result = await this.processApiCallWithException(async () => this.api.getAll(size, page));
        return await this.paginatorApiToPaginator(result!!,  async (d) => toContentGroup(d));
    }

    async getBySector(sectorId: number, text: string, size: number, page: number):Promise<Paginator<ContentGroup>> {
        const result = await this.processApiCallWithException(async () => this.api.getBySector(sectorId, text, size, page));
        return await this.paginatorApiToPaginator(result!!,  async (d) => toContentGroup(d));
    }

    async getById(id: number):Promise<ContentGroup | null> {
        const response = await this.api.getById(id);
        const { data } = response;
        if (response.status === "error") {
            throw response.error!!;
        }
        return toContentGroup(data!!);
    }

    async save(input:ContentGroup):Promise<ContentGroup | null> {
        const response = await this.api.save(toContentGroupApi(input));
        const {status, data} = response;

        if (status === "error") {
            throw response.error!!;
        }
        return data ? toContentGroup(data) : null;
    }

    async delete(input: ContentGroup) {
        input.id && await this.processApiCallWithException(async () => await this.api.delete(input.id!!));
    }
}
