import {Entity, GenericRepository, Reference} from "~/service/GenericRepository";

export const COLLECTION = "users";

export interface UserEntity extends Entity {
    id?: number;
    uid: string;
    email: string;
    name: string;
    sector?: number;
    phone?: string;
    roles: string[];
    disabled: boolean;
    groups:Reference[];
    sessions:Reference[];
}

export class UserRepository extends GenericRepository {

    public async getById(id:number):Promise<UserEntity | null> {
        const data: UserEntity[] = await this.getByFilters({ id });
        return data.length > 0 ? data[0] : null;
    }

    public async getByUid(uid:string):Promise<UserEntity | null> {
        const data: UserEntity[] = await this.getByFilters({ uid });
        return data.length > 0 ? data[0] : null;
    }

    public async getByEmail(email:string):Promise<UserEntity | null> {
        const data: UserEntity[] = await this.getByFilters( { email });
        return data.length > 0 ? data[0] : null;
    }

    // public async save(entity: UserEntity) {
    //     entity.collection = COLLECTION;
    //     return await this.persist(entity);
    // }

    protected getCollectionName(): string {
        return COLLECTION;
    }

}
