import {Area as AreaData} from "~/service/area/AreaApi";
import {Area} from "~/service/area/Area";
import {toApiAreaType, toAreaType} from "~/service/catalog/CatalogMapper";

export function toArea(input:AreaData): Area {
    return {
        ...input,
        type: toAreaType(input.type),
        isPrePos: input.type === "estados_animo",
    }
}

export function toAreaData(input:Area): AreaData {
    return {
        ...input,
        type: toApiAreaType(input.type)
    }
}
