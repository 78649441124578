import {GenericApi} from "~/service/GenericApi";

export type AreaType = "estados_animo" | "areas_cognitivas"

export type Gender = "Hombre" | "Mujer";

export type CognitiveImpairmentType = "DCG" | "ALZ" | "DCM" | "DCL" | "None";

export type VisualAbilityType = "Reducida" | "Regular" | "Limitada" | "Adecuada" | "Desconocida";

export class CatalogApi extends GenericApi {

}
