import {ActionTree, GetterTree, MutationTree} from "vuex";

interface State {}

const state = (): State => ({});

type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {};

export const actions: ActionTree<RootState, RootState> = {
};
