import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {AreaApi} from "~/service/area/AreaApi";
import {Area} from "~/service/area/Area";
import {toArea} from "~/service/area/AreaMapper";

export class AreaService extends GenericService<AreaApi> {

    constructor(context: Context | Store<any>) {
        super(context, new AreaApi(context));
    }

    async getAll():Promise<Area[]> {
        const result = await this.processApiCallWithException(async () => this.api.getAll());
        return result?.map(toArea) || [];
    }

    async getById(id: number):Promise<Area | null> {
        const result = await this.processApiCallWithException(async () => this.api.getById(id));
        return result ? toArea(result) : null;
    }
}
