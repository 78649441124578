import {GenericApi, VivirtualApiResponse} from "~/service/GenericApi";

export type Sector = {
  id: number;
  name: string;
  key?: string;
  disabled?:number;
};

export class SectorApi extends GenericApi {

  async getAll(): Promise<VivirtualApiResponse<Sector[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/sectors`),
        200
    );
  }

  async getById(id: number): Promise<VivirtualApiResponse<Sector | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/sectors/${id}`),
        200
    );
  }

}
