export default {
    home: (lang = 'en') => `/`,
    login: (lang = 'en') => `/login`,
    [`user`]: {
        dashboard: (lang = 'en') => `/dashboard`,
        list: (lang = 'en') => '/user/list',
        edit: (lang = 'en', id = ":id") => `/user/${id}`,
    },
    [`content`]: {
        list: (lang = 'en') => '/content/list',
        edit: (lang = 'en', id = ":id") => `/content/${id}`,
    },
    [`content-group`]: {
        list: (lang = 'en') => '/content-group/list',
        edit: (lang = 'en', id = ":id") => `/content-group/${id}`,
    },
    [`test`]: {
        list: (lang = 'en') => '/test/list',
        edit: (lang = 'en', id = ":id") => `/test/${id}`,
    },
};
