import {VivirtualApiResponse} from "~/api/GenericApi";
import {GenericApi} from "~/service/GenericApi";

export interface YTThumbnail {
  height: number;
  width: number;
  url: string;
}

export interface YTVideoDetails {
  videoId: string;
  title: string;
  author: string;
  shortDescription: string;
  thumbnail?: YTThumbnail;
}

export interface YTVideoInfoResponse {
  videoDetails: YTVideoDetails;
}

export interface StreetViewPanoramaResponse {
  metaurl: string;
  id: string;
  code: string;
  type: number;
  modifier: number;
  owner: string;
  author: string;
  section: number[];
  dimensions: number[];
  zoom: number;
  height: number;
  width: number;
  size: {
    x: number;
    y: number;
    z: number;
  };
  thumbnail: {
    url: string;
  };
  coordinates?: {
    latitude: string;
    longitude: string;
  }
}

const CF_HEADERS = {
  headers: {
    "x-vivirtual-psk": "v1v1rtu4l",
  }
}

export class MiscellaneousApi extends GenericApi {

  private async fetchGet<T>(url: string): Promise<VivirtualApiResponse<T>> {
    return this.errorHandler(
        async () => await this.$axios.get(url, {...CF_HEADERS}),
        200
    );
  }

  async getYoutubeInfo(code: string): Promise<VivirtualApiResponse<YTVideoInfoResponse>> {
    return await this.fetchGet(`https://external.vivirtual.com.es/${code}?type=youtube&info=1`);
  }

  async getStreetViewInfo(code: string): Promise<VivirtualApiResponse<StreetViewPanoramaResponse>> {
    return await this.fetchGet(`https://external.vivirtual.com.es/${code}?type=pano-meta`);
  }
}
