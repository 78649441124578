import {Entity, GenericRepository, Reference} from "~/service/GenericRepository";

export const COLLECTION = "sessions";

export interface SessionEntity extends Entity {
    id?:number;
    title: string;
    description: string;
    thumbnail?: string;
    thumbnailId?: string;
    guideId?: string;
    default: boolean;
    private: boolean;
    resource: Reference;
}

export class SessionContentRepository extends GenericRepository {

    public async getAll():Promise<SessionEntity[]> {
        return await this.getByFilters();
    }

    public async getById(id:string):Promise<SessionEntity | null> {
        return await this.getDocumentByKey(id);
    }

    public async getByResource(ref:string):Promise<SessionEntity[]> {
        const resource = await this.db.doc(ref);
        return resource == null ? [] : await this.getByFilters({ resource });
    }

    public async getPrivateById(id:number):Promise<SessionEntity | null> {
        const data: SessionEntity[] = await this.getByFilters({ private: true, id: id });
        return data.length > 0 ? data[0] : null;
    }

    public async getDefaultByResource(ref:string):Promise<SessionEntity | null> {
        const resource = await this.db.doc(ref);
        const data:SessionEntity[] = resource == null ? [] : await this.getByFilters({ resource });
        return data.sort((a, b) => {
            if (a.default && b.default) {
                return 0;
            } else if (a.default) {
                return 1;
            } else {
                return -1;
            }
        }).length > 0 ? data[0] : null;
    }

    protected getCollectionName(): string {
        return COLLECTION;
    }

}
