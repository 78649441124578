import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {encrypt} from "~/util/encode";
import {Content} from "~/service/content/ContentApi";
import {Sector} from "~/service/sector/SectorApi";
import {ContentGroup} from "~/service/content-group/ContentGroupApi";

export interface User {
  id?: number;
  uid?: string;
  email: string;
  name: string;
  surname: string;
  roles: string[];
  phone: string;
  sectorId: number;
  groups?: ContentGroup[];
  contents?: Content[];
  user_contents?: Content[];
  sector?: Sector;
}

export interface UserInfo {
  sessions: number;
  persons: number;
  personGroups: number;
  userId: number;
}

const BASE_PATH = `/api/v1/users`;

export class UserApi extends GenericApi {

  async getUserByEmail(email: string): Promise<VivirtualApiResponse<User>> {
    return this.errorHandler(
        async () => await this.$axios.get(`${BASE_PATH}/data/${encrypt(email)}`),
        200
    );
  }

  async getById(id: number): Promise<VivirtualApiResponse<User>> {
    return this.errorHandler(
        async () => await this.$axios.get(`${BASE_PATH}/${id}`),
        200
    );
  }

  async getAll(size: number, page: number): Promise<VivirtualApiResponse<Paginator<User>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`${BASE_PATH}?page=${page}&size=${size}`),
        200
    );
  }

  async getByText(text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<User>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`${BASE_PATH}?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async save(input: User): Promise<VivirtualApiResponse<User>> {
    return this.errorHandler(
        async () => await this.$axios.put(`${BASE_PATH}`, input),
        201
    );
  }

  async delete(id: number): Promise<VivirtualApiResponse<User>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`${BASE_PATH}/${id}`),
        200
    );
  }

  async getInfo(id: number): Promise<VivirtualApiResponse<UserInfo>> {
    return this.errorHandler(
        async () => await this.$axios.get(`${BASE_PATH}/${id}/info`),
        200
    );
  }
}
