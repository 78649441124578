import {Entity, GenericRepository} from "~/service/GenericRepository";
import {ResourceType, ResourceViewType} from "~/service/resource/Resource";

export const COLLECTION = "playlists";

export interface PlaylistItemEntity {
    name: string;
    data: string;
    time: number;
    copyright?:string;
}

export interface PlaylistEntity extends Entity {
    name: string;
    sector: number;
    description?:string;
    guideId?: string;
    thumbnail?: string;
    exercises?: string;
    type: ResourceType;
    viewType: ResourceViewType;
    items: Array<PlaylistItemEntity>;
}

export class PlaylistContentRepository extends GenericRepository {

    public async getAll():Promise<PlaylistEntity[]> {
        return await this.getByFilters();
    }

    public async getById(id:string):Promise<PlaylistEntity | null> {
        return await this.getDocumentByKey(id);
    }

    protected getCollectionName(): string {
        return COLLECTION;
    }

}
