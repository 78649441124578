import {User as UserApiData, UserInfo as UserInfoApiData} from "~/service/user/UserApi";
import {User, UserInfo} from "~/service/user/User";
import {toContent, toContentApi} from "~/service/content/ContentMapper";
import {toSector, toSectorApi} from "~/service/sector/SectorMapper";
import {toContentGroup, toContentGroupApi} from "~/service/content-group/ContentGroupMapper";

export function toUser(apiData: UserApiData): User {
    const {roles, sectorId, sector, contents, groups, user_contents } = apiData;
    return {
        ...apiData,
        sectorId: sectorId,
        disabled: 0,
        isClient: (roles || []).includes("client"),
        isAdmin: (roles || []).includes("admin"),
        contents: [...(user_contents || []).map(toContent), ...(contents || []).map(toContent)],
        contentGroups: (groups || []).map(toContentGroup),
        sector: sector && toSector(sector),
    };
}

export function toUserApi(user: User): UserApiData {
    const {sectorId, sector, contents, contentGroups } = user;
    return {
        ...user,
        sectorId: sectorId || sector?.id || 1,
        contents: (contents || []).filter(d => !d.ownerId).map(toContentApi),
        groups: (contentGroups || []).map(toContentGroupApi),
        sector: sector && toSectorApi(sector),
    }
}

export function toUserInfo(input: UserInfoApiData): UserInfo {
    const {userId, sessions, persons, personGroups } = input;
    return {
        userId,
        personGroups,
        persons,
        sessions,
    }
}
