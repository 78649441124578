import {Context, Middleware} from "@nuxt/types";
import {menu} from "~/model";

const authMiddleware: Middleware = (ctx: Context) => {
  const { store, redirect, route } = ctx;
  const { user } = store.state.auth;
  const { isAdmin = false, isClient = false, id = null } = user || {};
  const userParam = route.params.user ? parseInt(route.params.user) : id;
  const adminNeeded = route.meta[0]?.admin === true;
  const rootPath = isClient ? menu(ctx)['client'][0].to : "/";

  if (isAdmin || rootPath === route.path) {
    return;
  } else if (!user || !isClient || adminNeeded || userParam !== id) {
    return redirect(rootPath);
  } else if (route.path === "/") {
    return redirect(rootPath);
  }
};

export default authMiddleware;
