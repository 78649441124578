import {StreetViewPanoramaResponse, YTVideoInfoResponse} from "~/service/miscellaneous/MiscellaneousApi";
import {YTPanoramaMetadata, YTVideoMetadata} from "~/service/miscellaneous/Miscellaneous";

export const toVideoMetadata = (input: YTVideoInfoResponse | null): YTVideoMetadata | null => {
    return input === null ? null : {
        title: input.videoDetails?.title || "",
        author: input.videoDetails?.author || "",
        thumbnail: input.videoDetails.thumbnail?.url || ""
    };
}

export const toPanoramaMetadata = (input: StreetViewPanoramaResponse | null): YTPanoramaMetadata | null => {
    return input === null ? null : {
        code: input.code,
        author: input.author || "",
        thumbnail: input.thumbnail?.url || "",
        coordinates: input.coordinates || {
            latitude: "0",
            longitude: "0",
        }
    };
}
