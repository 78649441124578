import {ActionTree, GetterTree, MutationTree} from "vuex";
import {ByContentPaged, BySectorAndContentPaged, BySearchPaged, Paginator, Test} from "~/model";
import {callableErrorHandler} from "~/util/store";
import {EMPTY_PAGINATOR} from "~/store";

interface State {}

const state = (): State => ({});

type RootState = ReturnType<any>;
type CustomState = ReturnType<typeof state>;

export const getters: GetterTree<CustomState, RootState> = {};

export const mutations: MutationTree<CustomState> = {};

export const actions: ActionTree<CustomState, RootState> = {

    async getByText({ dispatch, commit, rootState }, {text, page} : BySearchPaged): Promise<Paginator<Test>> {
        return await callableErrorHandler(dispatch, async () => {
            return await this.$services.test.getByText(text, 10, page);
        }) || EMPTY_PAGINATOR;
    },

    async getByContent({ dispatch, commit, rootState }, {contentId, page} : ByContentPaged): Promise<Paginator<Test>> {
        return await callableErrorHandler(dispatch, async () => {
            return await this.$services.test.getAllByContent(contentId, 10, page);
        }) || EMPTY_PAGINATOR;
    },

    async getBySectorAndContent({ dispatch, commit, rootState }, {sectorId, contentId, page} : BySectorAndContentPaged): Promise<Paginator<Test>> {
        return await callableErrorHandler(dispatch, async () => {
            return await this.$services.test.getAllBySectorAndContent(sectorId, contentId, 10, page);
        }) || EMPTY_PAGINATOR;
    },

    async getById({ dispatch, commit, rootState }, id: number): Promise<Test | null> {
        return await callableErrorHandler(dispatch, async () => await this.$services.test.getById(id));
    },

    async save({ dispatch, commit, rootState }, data: Test): Promise<Test | null> {
        return await callableErrorHandler(dispatch, async () => await this.$services.test.save(data));
    },

    async delete(): Promise<void> {
    },
};
