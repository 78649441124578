import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {MiscellaneousApi} from "~/service/miscellaneous/MiscellaneousApi";
import {YTPanoramaMetadata, YTVideoMetadata} from "~/service/miscellaneous/Miscellaneous";
import {toPanoramaMetadata, toVideoMetadata} from "~/service/miscellaneous/MiscellaneousMapper";

export class MiscellaneousService extends GenericService<MiscellaneousApi> {

    constructor(context: Context | Store<any>) {
        super(context, new MiscellaneousApi(context));
    }

    async getYoutubeMetadata(code: string):Promise<YTVideoMetadata | null> {
        const data = await this.processApiCallWithoutException(async () => this.api.getYoutubeInfo(code));
        return toVideoMetadata(data);
    }

    async getStreetViewMetadata(code: string):Promise<YTPanoramaMetadata | null> {
        const data = await this.processApiCallWithoutException(async () => this.api.getStreetViewInfo(code));
        return toPanoramaMetadata(data);
    }
}
