import {ActionContext, Dispatch} from "vuex";
import {VivirtualApiResponse} from "~/api/GenericApi";
import {isEmpty} from "~/util/utils";
import {Sector} from "~/service/sector/Sector";

export interface UserState {
  uid?: string;
}

export async function getter<T, State extends UserState, RootState>(
  context: ActionContext<State, RootState>,
  defaultValue: T,
  producer: () => Promise<VivirtualApiResponse<T>>,
  callback: (d: T) => void = () => {},
  force: boolean = true,
  stateKey: keyof State | undefined = undefined  
): Promise<T> {
  const {state, commit, dispatch } = context || {};
  let results: T = defaultValue;
  try {
    let value: any = stateKey && state ? state[stateKey] : undefined;
    if (isEmpty(value) || force) {
      const response = await producer();
      if (response.status === "ok") {
        results = response.data || defaultValue;
        stateKey && commit(stateKey as string, results);
        callback(results);
      } else {
        throw response.error;
      }
    } else {
      results = value;
    }
  } catch (e) {
    await errorHandler(dispatch, e);
  }
  return results;
}

export async function errorHandler(dispatch: Dispatch, error: any) {
  const isDev = process.env.NODE_ENV === "development";
  const msg = isDev ? error : "unknown";
  isDev && console.error(error);
  await dispatch("setError", msg, { root: true });
}

export async function callableErrorHandler <T> (dispatch: Dispatch, callable: () => Promise<T>, defaultValue: T | null = null):Promise<T | null> {
  try {
    return isEmpty(defaultValue) ? await callable() : defaultValue;
  } catch (e) {
    await errorHandler(dispatch, e);
  }
  return defaultValue;
}

export function getSectorById(sectors: Sector[], id: number): Sector | undefined {
  return sectors.find(d => d.id === id);
}

// export async function errorHandler (dispatch: Dispatch, error: any) {
//   const msg = process.env.NODE_ENV === 'development' ? error : "unknown";
//   await dispatch("setError", msg, { root: true });
// }
