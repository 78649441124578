import {User} from "~/service/user/User";
import {Content} from "~/service/content/Content";
import {ContentGroup} from "~/service/content-group/ContentGroup";
import {Session, SessionPerson} from "~/service/session/Session";
import {ChartData, GlobalResultChartData, TimelineResultChartData} from "~/model/chart";
import {Person} from "~/service/person/Person";
import {PersonGroup} from "~/service/person-group/PersonGroup";
import {Paginator} from "~/service/Generic";
import {TableData, Test, TestQuestion} from "~/model/index";

export const createTableData = <T>(input: Partial<TableData<T>> = {}): TableData<T> => ({
    data: [],
    headers: [],
    page: 1,
    loading: false,
    total: 0,
    ...input,
});


export const createPaginator = <T>(data: T[] = []): Paginator<T> => ({
    data,
    current: 0,
    last: 0,
    size: data.length,
    total: data.length,
});

export const createUser = (): User => ({
    disabled: 0,
    email: "",
    contentGroups: [],
    isAdmin: false,
    isClient: false,
    name: "",
    surname: "",
    phone: "",
    roles: [],
    contents: [],
});

export const createContent = ():Content => ({
    ref: `${Date.now()}`,
    sectorId: 0,
    title: '',
    description: '',
    guide: '',
    exercises: '',
    private: false,
    resource: {
        code: '',
        name: '',
        type: 'video',
        viewType: '360',
        copyright: '',
        items: [],
    },
});

export const createContentGroup = ():ContentGroup => ({
    name: "",
    sectorId: 1,
    contents: [],
});

export const createPersonGroup = (): PersonGroup => ({
    userId: 0,
    sectorId: 1,
    name: '',
    contents: [],
    persons: [],
    sessions: [],
})

export const createPerson = (): Person => ({
    groupId: 0,
    name: "",
    surname: "",
    dob: null,
    gender: "male",
    cognitive: "none",
    visual: "normal",
    comments: "",
})

export const createSession = ():Session => ({
    sectorId: 0,
    personGroupId: 0,
    contentId: 0,
    testId: 0,
    name: "",
    date: null,
    persons: 0,
    comments: "",
    participants: []
});

export const createSessionPerson = (input: Partial<SessionPerson> = {}) :SessionPerson => ({
    sessionId: 0,
    personId: 0,
    date: null,
    comments: "",
    ...input,
});

export const createTest = (input: Partial<Test> = {}):Test => ({
    name: "",
    type: "cognitive",
    disabled: 0,
    questions: [],
    ...input,
});

export const createTestQuestion = (input: Partial<TestQuestion> = {}):TestQuestion => ({
    testId: 0,
    areaId: 0,
    text: "",
    options: [],
    ref: `${Date.now()}`,
    ...input,
});

export const createChartData = (): ChartData => ({
    labels: [],
    datasets: [],
});

export const createGlobalResultChartData = (): GlobalResultChartData => ({
    sessions: 0,
    evaluated: 0,
    moods: createChartData(),
    cognitive: createChartData(),
});

export const createTimelineResultChartData = ():TimelineResultChartData => ({
    moods: createChartData(),
    cognitive: createChartData(),
});
