import {Entity, GenericRepository} from "~/service/GenericRepository";
import {Resource} from "~/service/resource/Resource";
import {Store} from "vuex";
import {Context} from "@nuxt/types";
import {PlaylistContentRepository, PlaylistEntity} from "~/service/content/repository/PlaylistContentRepository";
import {ResourceContentRepository, ResourceEntity} from "~/service/content/repository/ResourceContentRepository";
import {SessionContentRepository} from "~/service/content/repository/SessionContentRepository";
import {UserRepository} from "~/service/user/UserRepository";

export interface ContentEntity extends Entity {
    id?:number;
    sector: number;
    title: string;
    description: string;
    guideId?: string;
    thumbnail?: string;
    exercises?: string;
    disabled?:number;
    session?:string;
    private?: boolean;
    resource?: Resource;
}

const COLLECTION = "contents";

export class ContentRepository extends GenericRepository {
    private readonly userRepository: UserRepository;
    private readonly sessionContentRepository: SessionContentRepository;
    private readonly resourceContentRepository: ResourceContentRepository;
    private readonly playlistContentRepository: PlaylistContentRepository;

    constructor(context: Context | Store<any>) {
        super(context);
        this.userRepository = new UserRepository(context);
        this.sessionContentRepository = new SessionContentRepository(context);
        this.resourceContentRepository = new ResourceContentRepository(context);
        this.playlistContentRepository = new PlaylistContentRepository(context);
    }

    public async getAll():Promise<ContentEntity[]> {
        const resources:ContentEntity[] = (await this.resourceContentRepository.getAll()).map(ContentRepository.fromResourceToContent);
        const playlists:ContentEntity[] = (await this.playlistContentRepository.getAll()).map(ContentRepository.fromPlaylistToContent);
        return [...resources, ...playlists];
    }

    public async getById(id:string):Promise<ContentEntity | null> {
        // getting from playlists
        let result: ContentEntity | null;
        const playlist = await this.playlistContentRepository.getById(id);
        if (playlist != null) {
            result = ContentRepository.fromPlaylistToContent(playlist);
        } else {
            const resource = await this.resourceContentRepository.getById(id);
            result = resource == null ? null : ContentRepository.fromResourceToContent(resource);
        }
        return result;
    }

    protected getCollectionName(): string {
        return COLLECTION;
    }

    private static fromPlaylistToContent(input: PlaylistEntity):ContentEntity {
        return {
            ref: input.ref,
            collection: input.collection,
            title: input.name,
            description: input.description || "",
            thumbnail: input.thumbnail,
            exercises: input.exercises,
            guideId: input.guideId,
            sector: input.sector,
            disabled: 0,
            resource: {
                code: "playlist",
                name: input.name,
                type: "playlist",
                viewType: "360",
                items: input.items.map(d => ({
                    code: d.data,
                    name: d.name,
                    type: "panorama",
                    viewType: "360",
                    copyright: d.copyright,
                    time: d.time
                }))
            }
        }
    }

    private static fromResourceToContent(input: ResourceEntity):ContentEntity {
        return {
            ref: input.ref,
            collection: input.collection,
            title: input.name,
            description: input.description || "",
            thumbnail: input.thumbnail,
            exercises: input.exercises,
            guideId: input.guideId,
            sector: input.sector,
            disabled: 0,
            resource: {
                id: input.fileId,
                code: input.filename!!,
                name: input.name,
                type: input.type,
                viewType: input.viewType,
                copyright: input.copyright,
            }
        }
    }
}
