import Vue from "vue";
import {AlertData} from "~/store";
import {Context} from "@nuxt/types";

const isDev = () => process.env.NODE_ENV === "development";

const dispatch = (context: Context | any) => {
    const { dispatch = () => {} } = context.store || context.$store || {};
    return dispatch;
}

export const showAlert = async (context: Context | any, data: AlertData) => {
    try {
        await dispatch(context)('showAlert', data);
    } catch (e) {
        isDev() && console.error("Unhandled error", e);
    }
}

export const showError = async (context: Context | any, err: Error | string = 'unknown', vm?: Vue, info?: string) => {
    try {
        await dispatch(context)('showAlert', { message: 'common.error.generic', type: "error", });
    } catch (e) {
        isDev() && console.error("Unhandled error", e);
    } finally {
        isDev() && console.error("Unhandled error", err);
    }
}

export const showProcessing = async (context: Context | any, processing: boolean) => {
    try {
        await dispatch(context)('setProcessing', processing);
    } catch (e) {
        isDev() && console.error("Unhandled error", e);
    }
}

export const awaitAll = async (input: (()=>Promise<void>)[]): Promise<void> => {
    await Promise.all(input.map(d => d()));
}

export const isMobile = (context: any) => context?.$vuetify?.breakpoint?.xsOnly;

export const navigate = async (context: Vue, model: string, action: string, id?:number) => {
    const path = context.$provider.page(model, action, id || "new");
    path && await context.$router.push({ path });
}

export const navigateBack = async (context: Vue, model: string | null = null, action: string | null = null) => {
    context.$router.back();
}
