import {
    AreaResult as AreaResultApiData,
    Result as ResultApiData,
    SessionResult as SessionResultApiData,
} from "~/service/results/ResultApi";
import {AreaResult, Result, SessionResult} from "~/service/results/Result";
import {toArea} from "~/service/area/AreaMapper";

export function toResult(input: ResultApiData): Result {
    const { sessions = [] } = input;
    return {
        ...input,
        sessions: sessions.map(toSessionResult),
    }
}

export function toSessionResult(input: SessionResultApiData): SessionResult {
    const { values = [], name } = input;
    return {
        ...input,
        name: name && name !== "" ? name : "No name",
        values: values.map(toAreaResult),
    }
}

export function toAreaResult(input: AreaResultApiData): AreaResult {
    const { area } = input;
    return {
        ...input,
        area: area && toArea(area),
    }
}
