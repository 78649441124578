import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {CognitiveImpairmentType, Gender, VisualAbilityType} from "~/service/catalog/CatalogApi";
import {PersonGroup} from "~/service/person-group/PersonGroupApi";

export type Person = {
  id?: number;
  groupId: number;
  name: string;
  surname: string;
  dob: string | null;
  gender: Gender;
  cognitive_impairment_type: CognitiveImpairmentType;
  visual_ability: VisualAbilityType;
  comments: string;
  disabled?: number;
  group?: PersonGroup
}

export class PersonApi extends GenericApi {

  async getAllByGroup(groupId:number,): Promise<VivirtualApiResponse<Person[]>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/persons`),
        200
    );
  }

  async getByUser(userId:number, text:string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Person>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/persons?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByGroup(groupId:number, text:string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Person>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/persons?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByGroupAndId(groupId:number, id: number): Promise<VivirtualApiResponse<Person | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${groupId}/persons/${id}`),
        200
    );
  }

  async getById(personId:number): Promise<VivirtualApiResponse<Person | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/persons/${personId}`),
        200
    );
  }

  async save(input: Person): Promise<VivirtualApiResponse<void>> {
    return this.errorHandler(
        async () => await this.$axios.put(`/api/v1/person-groups/${input.groupId}/persons`, input),
        201
    );
  }

  async delete(groupId:number, id: number): Promise<VivirtualApiResponse<void>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`/api/v1/person-groups/${groupId}/persons/${id}`),
        200
    );
  }

}
