import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {TestApi} from "~/service/test/TestApi";
import {Test} from "~/service/test/Test";
import {Paginator} from "~/service/Generic";
import {toTest, toTestData} from "~/service/test/TestMapper";

export class TestService extends GenericService<TestApi> {

    constructor(context: Context | Store<any>) {
        super(context, new TestApi(context));
    }

    async getByUser(userId:number, text: string, size: number = 10, page: number = 1):Promise<Paginator<Test>> {
        const result = await this.processApiCallWithException(async () => this.api.getByUser(userId, text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toTest(d));
    }

    async getByText(text: string, size: number, page: number):Promise<Paginator<Test>> {
        const result = await this.processApiCallWithException(async () => this.api.getByText(text, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toTest(d));
    }

    async getAllByContent(content: number, size: number, page: number):Promise<Paginator<Test>> {
        const result = await this.processApiCallWithException(async () => this.api.getAllByContent(content, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toTest(d));
    }

    async getAllBySectorAndContent(sector: number, content: number, size: number, page: number):Promise<Paginator<Test>> {
        const result = await this.processApiCallWithException(async () => this.api.getAllBySectorAndContent(sector, content, size, page));
        return await this.paginatorApiToPaginator(result!!, async (d) => toTest(d));
    }

    async getById(id: number, eager: boolean = true):Promise<Test | null> {
        const result = await this.processApiCallWithException(async () => this.api.getById(id, eager));
        return result ? toTest(result) : null;
    }

    async save(input: Test):Promise<Test | null> {
        const data = toTestData(input);
        let result = await this.processApiCallWithException(async () => this.api.save(data));
        result = await this.processApiCallWithException(async () => this.api.getById(result?.id!!, true));
        return result ? toTest(result) : null;
    }

    async delete(input: Test):Promise<void> {
        input.id && await this.processApiCallWithException(async () => this.api.delete(input.id!!));
    }
}
