import { GetterTree, ActionTree, MutationTree } from "vuex";

type Province = {
  code: string;
  text: string;
};

type State = {
  provinces: Province[];
};

export const state = (): State => ({
  provinces: [],
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  provinces: (state) => state.provinces,
};

export const mutations: MutationTree<RootState> = {
  setProvinces: (state, provinces: Province[]) => {
    state.provinces = provinces;
  },
};

export const actions: ActionTree<RootState, RootState> = {
  async getProvinces({ commit, dispatch }): Promise<Province[]> {
    if (this.state.provinces.length > 0) {
      return this.state.provinces;
    } else {
      const response: Response = await fetch(
        "https://public.opendatasoft.com/api/records/1.0/search/?dataset=provincias-espanolas&q=&sort=provincia&facet=ccaa&facet=provincia"
      );
      if (response.status === 200) {
        const data = await response.json();
        const values = (data.records as any[]).map((d) => {
          const { provincia, texto } = d;
          return { code: provincia, text: texto } as Province;
        });
        await commit("setProvinces", values);
        return values;
      }
      return [];
    }
  },
};
