const ENCRYPT_PREFIX = "am9";
const ENCRYPT_SUFFIX = "1==";

export function base64Encode(data: string): string {
  return btoa(data);
}

export function base64Decode(data: string): string {
  return atob(data);
}

export function encrypt(data:string) : string {
  const encoded = ENCRYPT_PREFIX + base64Encode(data);
  return encoded.endsWith("=") ? encoded : (encoded + ENCRYPT_SUFFIX);
}

export function decrypt(data:string) : string {
  const value = data.endsWith(ENCRYPT_SUFFIX) ? data.substr(3, data.length - 6) : data.substr(3, data.length - 3);
  return base64Decode(value);
}