import {ActionTree, GetterTree, MutationTree} from "vuex";
import {BySearchPaged, Paginator, User} from "~/model";
import {callableErrorHandler} from "~/util/store";
import {EMPTY_PAGINATOR} from "~/store";

interface State {}

const state = (): State => ({});

type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {};

export const actions: ActionTree<RootState, RootState> = {
  async getByText({ dispatch, commit, rootState }, {text, page} : BySearchPaged): Promise<Paginator<User>> {
    return await callableErrorHandler(dispatch, async () => {
      return await this.$services.user.getByText(text, 10, page);
    }) || EMPTY_PAGINATOR;
  },

  async getAllPaged({dispatch}, page: number): Promise<Paginator<User>> {
    return await callableErrorHandler(dispatch, async () => {
      return await this.$services.user.getAll(10, page);
    }) || EMPTY_PAGINATOR;
  },

  async getById({ dispatch, commit }, id: number): Promise<User | null> {
    return await callableErrorHandler(dispatch, async () => {
      return await this.$services.user.getById(id);
    });
  },

  async save({ state, commit, dispatch }, data: User): Promise<User | null> {
    return await callableErrorHandler(dispatch, async () => {
      await this.$services.user.save(data);
      return data;
    });
  },

};
