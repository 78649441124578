






















/* eslint-disable no-new */
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";
import { Component, Vue } from "nuxt-property-decorator";
import { mapGetters, mapActions } from "vuex";

@Component({
  components: {
    SlideYDownTransition,
    ZoomCenterTransition,
  },
  name: 'empty',
  computed: mapGetters({}),
  methods: mapActions({}),
})
export default class extends Vue {}
