import {GenericApi, VivirtualApiResponse} from "~/service/GenericApi";
import {Area} from "~/service/area/AreaApi";
import {EvaluationType} from "~/service/catalog/Catalog";

export interface AreaResult {
  count: number;
  total: number;
  value: number;
  type: EvaluationType;
  area: Area;
}

export interface SessionResult {
  id: number;
  date: string | null;
  timestamp: number;
  name: string | null;
  values: AreaResult[];
}

export interface Result {
  persons: number[];
  evaluated: number;
  sessions: SessionResult[];
}

export class ResultApi extends GenericApi {

  async getByPersonId(personId:number, sessionId: number | null = null): Promise<VivirtualApiResponse<Result>> {
    const url = sessionId === null ?
        `/api/v1/persons/${personId}/results` :
        `/api/v1/sessions/${sessionId}/persons/${personId}/results`
    return this.errorHandler(
        async () => await this.$axios.get(url),
        200
    );
  }

  async getByPersonGroupId(personGroupId:number): Promise<VivirtualApiResponse<Result>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/person-groups/${personGroupId}/results`),
        200
    );
  }

  async getByUserId(userId:number): Promise<VivirtualApiResponse<Result>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/results`),
        200
    );
  }

  async getBySessionId(sessionId:number): Promise<VivirtualApiResponse<Result>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/sessions/${sessionId}/results`),
        200
    );
  }
}
