import {Provider} from "~/plugins/provider";
import pages from "~/config/pages";

const translate = (context: any, key: string) =>
    context.$t ? context.$t(key) : '';

const icon = (context: any, model: string, action: string = 'default') =>
    context.$provider ? (context.$provider as Provider).icon(model, action) : '';

export const menu = (context: any) => ({
    unregistered: [],
    client: [
        {
            title: translate(context, "menu.dashboard"),
            icon: icon(context, 'dashboard'),
            to: pages.user.dashboard(),
        },
        // {
        //     title: translate(context, "menu.calendar"),
        //     icon: icon(context, 'sessions', 'calendar'),
        //     to: pages.session.calendar(),
        // },
    ],
    admin: [
        {
            title: translate(context, "menu.home"),
            icon: icon(context, 'home'),
            to: pages['home'](),
        },
        {
            title: translate(context, "menu.users"),
            icon: icon(context, 'user'),
            to: pages['user'].list(),
        },
        {
            title: translate(context, "menu.contents"),
            icon: icon(context, 'content'),
            to: pages['content'].list(),
        },
        {
            title: translate(context, "menu.contentgroups"),
            icon: icon(context, 'content-group'),
            to: pages['content-group'].list(),
        },
        {
            title: translate(context, "menu.tests"),
            icon: icon(context, 'test'),
            to: pages['test'].list(),
        },
    ],
});
