import {ActionTree, GetterTree, MutationTree} from "vuex";
import {callableErrorHandler, UserState} from "~/util/store";
import {AreaType, Catalog, CognitiveImpairmentType, Gender, Sector, VisualAbilityType} from "~/model";
import {Area} from "~/service/area/Area";

interface State extends UserState {
  areas: Area[];
  sectors: Sector[];
}

const state = (): State => ({
  areas: [],
  sectors: [],
});

type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, RootState> = {
  areas: (state) => state.areas,
  sectors: (state) => state.sectors,
};

export const mutations: MutationTree<RootState> = {
  areas: (state, areas: Area[]) => (state.areas = areas),
  sectors: (state, sectors: Sector[]) => (state.sectors = sectors),
};

export const actions: ActionTree<RootState, RootState> = {
  
  async getGenderTypes(): Promise<Catalog<Gender>[]> {
    return this.$services.catalog.getGenderTypes();
  },

  async getCognitiveImpairmentTypes(): Promise<Catalog<CognitiveImpairmentType>[]> {
    return this.$services.catalog.getCognitiveImpairmentTypes();
  },

  async getVisualAbilityTypes(): Promise<Catalog<VisualAbilityType>[]> {
    return this.$services.catalog.getVisualAbilityTypes();
  },

  async getAreaTypes(): Promise<Catalog<AreaType>[]> {
    return this.$services.catalog.getAreaType();
  },

  async getAreas({ dispatch, getters, commit }): Promise<Area[]> {
    const data: Area[] = getters.areas;
    if (data && data.length > 0) {
      return data;
    }
    return await callableErrorHandler(dispatch, async () => {
      const results = (await this.$services.area.getAll()).filter(d => d.disabled === 0);
      await commit("areas", [...results]);
      return results;
    }) || [];
  },

  async getAreaById( { getters, dispatch, commit }, id: number): Promise<Area | null> {
    const data: Area[] = await dispatch('getAreas');
    return data?.filter(d => d.id === id)[0];
  },

  async getSectors( { dispatch, commit, getters } ): Promise<Sector[]> {
    const data: Sector[] = getters.sectors;
    if (data && data.length > 0) {
      return data;
    }
    return await callableErrorHandler(dispatch, async () => {
      const results = await this.$services.sector.getAll();
      await commit("sectors", [...results]);
      return results;
    }) || [];
  },

  async getSectorById( { getters, dispatch, commit }, id: number): Promise<Sector | null> {
    const data: Sector[] = await dispatch('getSectors');
    return data?.filter(d => d.id === id)[0];
  },
};
