import {ActionTree, GetterTree, MutationTree} from "vuex";
import {Content} from "~/model";
import {UserState} from "~/util/store";

export interface State extends UserState {
  contents: Content[];
}

const state = (): State => ({
  contents: [],
});

type RootState = ReturnType<any>;
type CustomState = ReturnType<typeof state>;

export const getters: GetterTree<CustomState, RootState> = {
  uid: (state) => state.uid,
  contents: (state) => state.contents,
};

export const mutations: MutationTree<CustomState> = {
  uid: (state, uid: string) => (state.uid = uid),
  contents: (state, contents: Content[]) => (state.contents = contents),
};

export const actions: ActionTree<CustomState, RootState> = {
  async initialize({ state, commit }, uid: string) {
    if (uid !== state.uid) {
      commit("contents", []);
    }
  },
};
