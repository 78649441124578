import {Context} from "@nuxt/types";
import {Store} from "vuex";
import {GenericService} from "~/service/GenericService";
import {ResultApi} from "~/service/results/ResultApi";
import {Result} from "~/service/results/Result";
import {toResult} from "~/service/results/ResultMapper";

const createEmpty = () =>({
    evaluated: 0,
    persons: [],
    sessions: [],
});

export class ResultService extends GenericService<ResultApi> {

    constructor(context: Context | Store<any>) {
        super(context, new ResultApi(context));
    }

    async getByUserId(userId:number): Promise<Result> {
        const result = await this.processApiCallWithoutException(async () => this.api.getByUserId(userId));
        return toResult(result || createEmpty());
    }

    async getByPersonId(personId:number, sessionId: number | null = null): Promise<Result> {
        const result = await this.processApiCallWithoutException(async () => this.api.getByPersonId(personId, sessionId));
        return toResult(result || createEmpty());
    }

    async getByPersonGroupId(personGroupId:number): Promise<Result> {
        const result = await this.processApiCallWithoutException(async () => this.api.getByPersonGroupId(personGroupId));
        return toResult(result || createEmpty());
    }

    async getBySessionId(sessionId:number): Promise<Result> {
        const result = await this.processApiCallWithoutException(async () => this.api.getBySessionId(sessionId));
        return toResult(result || createEmpty());
    }
}
