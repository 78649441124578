import {Content as ContentApiData} from "~/service/content/ContentApi";
import {Content} from "~/service/content/Content";
import {Resource} from "~/service/resource/Resource";
import {toSector, toSectorApi} from "~/service/sector/SectorMapper";
import {toUser} from "~/service/user/UserMapper";

const EMPTY_RESOURCE: Resource = {
    code: '',
    name: '',
    type: 'video',
    viewType: '360',
    copyright: '',
    thumbnail: '',
    items: [],
};

export function toContent(input: ContentApiData):Content {
    const {id, title, description, sectorId, sector, disabled, exercises, guide, thumbnail, data, owner, ownerId} = input;
    return {
        id,
        title,
        description,
        sectorId,
        disabled,
        exercises,
        guide,
        thumbnail,
        ownerId,
        private: !!ownerId,
        owner: owner && toUser(owner),
        sector: sector && toSector(sector),
        resource: { ...EMPTY_RESOURCE, ...(data || {}) },
        ref: `${id || Date.now()}`,
    }
}

export function toContentApi(input: Content): ContentApiData {
    const {id, title, description, sectorId, sector, disabled, exercises, guide, thumbnail, resource, external, ownerId } = input;
    return {
        id,
        title,
        description,
        sectorId,
        disabled,
        exercises,
        guide,
        thumbnail,
        external,
        ownerId,
        sector: sector && toSectorApi(sector),
        data: resource,
    }
}
