import {Context} from "@nuxt/types";
import {AxiosResponse} from "axios";
import {VivirtualApiResponse} from "~/api/GenericApi";

import {Store} from "vuex";

export * from '~/api/GenericApi';

export type InternalResponse<T = any> = {
  data: T;
  status: number;
};

export interface Paginator<T> {
  current_page: number;
  data: T[],
  per_page: number
  last_page: number;
  total: number
}

export abstract class GenericApi {
  private context!: Context | Store<any>;

  constructor(context: Context | Store<any>) {
    this.context = context;
  }

  protected get $axios() {
    return this.context.$axios;
  }

  protected async errorHandler<T>(
    callback: () => Promise<AxiosResponse | InternalResponse>,
    status: number = 200,
    mapper: (data: any) => T = (d) => d
  ): Promise<VivirtualApiResponse<T>> {
    try {
      const response = await callback();
      if (response.status === status) {
        return {
          status: "ok",
          data: mapper(response.data),
        };
      } else {
        return {
          status: "error",
          error: response.data,
        };
      }
    } catch (e) {
      return {
        status: "error",
        error: "There is a problem with this operation, please try later",
      };
    }
  }
}
