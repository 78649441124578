import {GenericApi, Paginator, VivirtualApiResponse} from "~/service/GenericApi";
import {AreaType} from "~/service/catalog/CatalogApi";
import {Content} from "~/service/content/ContentApi";
import {Sector} from "~/service/sector/SectorApi";
import {Area} from "~/service/area/AreaApi";

export interface TestQuestion {
  id?: number;
  testId?: number;
  areaId: number;
  text: string;
  disabled?: number;
  options: TestOption [];
  area?: Area;
}

export interface TestOption {
  id?: number;
  questionId: number;
  text: string;
  value: number;
  disabled?: number;
}

export interface Test {
  id?:number;
  contentId?:number;
  sectorId?:number;
  name:string;
  type:AreaType;
  disabled:number;
  used?: number;
  questions?: TestQuestion[];
  content?: Content;
  sector?: Sector;
}

export class TestApi extends GenericApi {

  async getByUser(userId: number, text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Test>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/users/${userId}/tests?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getByText(text: string, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Test>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/tests?text=${text}&page=${page}&size=${size}`),
        200
    );
  }

  async getAllByContent(contentId: number, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Test>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/contents/${contentId}/tests?page=${page}&size=${size}`),
        200
    );
  }

  async getAllBySectorAndContent(sectorId: number, contentId: number, size: number, page: number): Promise<VivirtualApiResponse<Paginator<Test>>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/sectors/${sectorId}/contents/${contentId}/tests?page=${page}&size=${size}`),
        200
    );
  }

  async getById(id: number, eager: boolean = false): Promise<VivirtualApiResponse<Test | null>> {
    return this.errorHandler(
        async () => await this.$axios.get(`/api/v1/tests/${id}?eager=${+ eager}`),
        200
    );
  }

  async save(data: Test): Promise<VivirtualApiResponse<Test | null>> {
    return this.errorHandler(
        async () => await this.$axios.put(`/api/v1/tests`, data),
        201
    );
  }

  async delete(id: number): Promise<VivirtualApiResponse<void>> {
    return this.errorHandler(
        async () => await this.$axios.delete(`/api/v1/tests/${id}`),
        200
    );
  }

}
