import {Test as TestData, TestQuestion as TestQuestionData} from "~/service/test/TestApi";
import {Test, TestQuestion} from "~/service/test/Test";
import {toApiAreaType, toAreaType} from "~/service/catalog/CatalogMapper";
import {toSectorApi, toSector} from "~/service/sector/SectorMapper";
import {toContent, toContentApi} from "~/service/content/ContentMapper";
import {toArea, toAreaData} from "~/service/area/AreaMapper";

export function toTest(data: TestData): Test {
    return {
        ...data,
        used: data.used || 0,
        type: toAreaType(data.type),
        sector: data.sector && toSector(data.sector),
        content: data.content && toContent(data.content),
        questions: data.questions?.map(toTestQuestion) || []
    }
}

export function toTestQuestion(data: TestQuestionData): TestQuestion {
    return {
        ...data,
        area: data.area && toArea(data.area),
        ref: `${data.id || Date.now()}`
    }
}

export function toTestData(data: Test): TestData {
    return {
        ...data,
        type: toApiAreaType(data.type),
        sector: data.sector && toSectorApi(data.sector),
        content: data.content && toContentApi(data.content),
        questions: data.questions?.map(toTestQuestionData) || []
    }
}

export function toTestQuestionData(data: TestQuestion): TestQuestionData {
    return {
        ...data,
        area: data.area && toAreaData(data.area),
    }
}
