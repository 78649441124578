











import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component({})
export default class extends Vue {
  @Prop({ type: Boolean, default: false })
  private show!: boolean;

  @Prop({ default: 6 })
  private zIndex!: number;
}
