import {ActionTree, GetterTree, MutationTree} from "vuex";
import {ByUserPaged, Paginator, PersonGroup} from "~/model";
import {callableErrorHandler, UserState} from "~/util/store";
import {State as AuthState} from '~/store/auth';
import {EMPTY_PAGINATOR} from "~/store";

interface State extends UserState {
  groups: PersonGroup[];
}

type RootState = ReturnType<any>;
type CustomState = ReturnType<typeof state>;


const state = (): State => ({
  groups: [] as PersonGroup[],
});

export const getters: GetterTree<CustomState, RootState> = {
  uid: (state) => state.uid,
  groups: (state) => state.groups,
};

export const mutations: MutationTree<CustomState> = {
  uid: (state, uid: string) => (state.uid = uid),
  groups: (state, groups: PersonGroup[]) => (state.groups = groups),
};

export const actions: ActionTree<CustomState, RootState> = {
  async initialize({ state, commit }, uid: string) {
    if (uid !== state.uid) {
      commit("groups", []);
    }
  },

  async getByUser({ dispatch, commit, rootState }, {userId, page, text}:ByUserPaged): Promise<Paginator<PersonGroup>> {
    return await callableErrorHandler(dispatch, async () => {
      const { auth } = rootState as { auth : AuthState};
      const authUserId: number = auth.user?.id || 0;
      const isAdmin: boolean = !!auth.user?.isAdmin;
      if (isAdmin || authUserId === userId) {
        return await this.$services.personGroup.getByUser(userId, text || '', 10, page);
      }
      return EMPTY_PAGINATOR;
    }) || EMPTY_PAGINATOR;
  },

  async getById({ dispatch, commit, rootState }, id: number): Promise<PersonGroup | null> {
    return await callableErrorHandler(dispatch, async () => {
      return await this.$services.personGroup.getById(id);
    });
  },

  async save({ dispatch}, data: PersonGroup): Promise<PersonGroup | null> {
    return await callableErrorHandler(dispatch, async () => await this.$services.personGroup.save(data));
  },

  async delete({ dispatch}, data: PersonGroup): Promise<void> {
    await callableErrorHandler(dispatch, async () => await this.$services.personGroup.delete(data));
  },
};
